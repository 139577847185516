import { InvoiceApiService } from './../services/invoice-api-service';
import { autoinject, BindingEngine, computedFrom, observable } from "aurelia-framework";
import { CustomColDef, CustomLogger, EditingModeEnum, EntityDetailViewModelBase, EnumerationTypeService, FieldType, ServiceBase, Various, IMenuGroup, GlobalLoaderService, UIInternal, ActionDialogBoxInputParameters, DialogBoxViewModel, KeyboardShortcut, PictureHelper } from "digiwall-lib";
import { Merlin } from "generated";
import * as Constants from '../constants';
import { Router } from 'aurelia-router';
import { FilterQueryOp, Predicate } from "breeze-client";
import { ColDef, RowNode, ColSpanParams, ICellRendererParams, GridOptions, CellValueChangedEvent } from 'ag-grid-community';
import { ModuleListTreeData } from 'module-list-tree-data/module-list-tree-data';
import { VersionOfferOverlayComp, FullWidthCellRenderer, LINE_HAS_BEEN_ADDED } from 'projects/quotes/version-offer-overlay-comp';
import { MeteringMenuItems } from 'resources/metering/metering-menu-items';
import * as toastr from 'toastr';
import { HighlightSearchTextValueConverter } from 'resources/value-converters';
import { IDataLineApiService, MeteringTotalPrices } from 'services/i-data-line-api-service';
import { SellingInvoiceLineCommentApiService } from 'services/price-offer-line-comment-api-service';
import { DebugTotalPricesError } from 'price-offer-lines/debug-total-prices-error/debug-total-prices-error';
import { SendOffer, ReturnParams, GenerationApiParams } from 'projects/quotes/send-offers/send-offer';
import { ReportApiService } from 'services/report-api-service';
import moment from 'moment';

@autoinject
export class SellingInvoiceDetail extends EntityDetailViewModelBase<Merlin.Web.Model.SellingInvoice> {
  private thirdPartyService: ServiceBase<Merlin.Web.Model.ThirdParty>;
  private projectService: ServiceBase<Merlin.Web.Model.Project>;
  private vatService: ServiceBase<Merlin.Web.Model.VATLevel>;
  private vatList: Array<Merlin.Web.Model.VATLevel>;
  private appParameterService: ServiceBase<Merlin.Web.Model.ApplicationParameter>;
  private appParameter: Merlin.Web.Model.ApplicationParameter;
  private invoiceStatusService: EnumerationTypeService;
  private payementStatusService: EnumerationTypeService;
  public noDataLabel = this.i18n.tr("grid.noRowsToShow");
  public gridOptions: GridOptions = {};
  public listTreeData: ModuleListTreeData;
  public versionId: number;
  public screenExpand: boolean = false;
  public title: string;
  private highlightSearchTextValueConverter: HighlightSearchTextValueConverter;
  public quickFilter: string;
  private unitList: Array<Merlin.Web.Model.Unit>;
  private unitService: ServiceBase<Merlin.Web.Model.Unit>;
  private isFromProject: boolean = false;
  private menuItems: Array<IMenuGroup>;
  private INVOICE_ID = Constants.SellingInvoiceTypeId.Invoice;
  private CREDIT_NOTE_ID = Constants.SellingInvoiceTypeId.CreditNote;
  private BILLING_VOUCHER_ID = Constants.SellingInvoiceTypeId.BillingVoucher;
  private DRAFT_ID = Constants.SellingInvoiceStatusId.Draft;
  private showGrid: boolean = true;
  private debugMode: boolean = false;
  private signatoryService: ServiceBase<Merlin.Web.Model.InvoiceSignatory>;
  private invoiceService: ServiceBase<Merlin.Web.Model.SellingInvoice>;
  private keyDownFunction;


  @computedFrom("entity", "entity.invoiceNumber")
  public get documentTitle() {
    return this.entity?.invoiceNumber;
  }
  @computedFrom("entity", "entity.invoiceNumber")
  public get ribbonHeaderText() {
    let text = "";
    switch (this.entity.invoiceTypeId) {
      case Constants.SellingInvoiceTypeId.Invoice:
        text += this.i18n.tr("sellinginvoice.sellinginvoice");
        break;
      case Constants.SellingInvoiceTypeId.CreditNote:
        text += this.i18n.tr("sellinginvoice.creditNote");
        break;
      case Constants.SellingInvoiceTypeId.BillingVoucher:
        text += this.i18n.tr("sellinginvoice.billingVoucher");
        break;
    }
    return text + " : " + (this.entity.invoiceNumber ?? this.entity.id);

  }
  public ressourceName: string = Constants.EntityTypeNames.SellingInvoice;
  constructor(router: Router, logger: CustomLogger, public bindingEngine: BindingEngine, private invoiceApiService: InvoiceApiService, public element: Element, private gls: GlobalLoaderService, private meteringMenuItems: MeteringMenuItems, private commentService: SellingInvoiceLineCommentApiService, private reportApiService: ReportApiService, private fileHelper: PictureHelper) {
    super(router, logger);
    super.initialize(new ServiceBase<Merlin.Web.Model.SellingInvoice>(Constants.EntityTypeNames.SellingInvoice));
    this.thirdPartyService = new ServiceBase<Merlin.Web.Model.ThirdParty>(Constants.EntityTypeNames.ThirdParty);
    this.projectService = new ServiceBase<Merlin.Web.Model.Project>(Constants.EntityTypeNames.Project);
    this.appParameterService = new ServiceBase<Merlin.Web.Model.ApplicationParameter>(Constants.EntityTypeNames.ApplicationParameter);
    this.vatService = new ServiceBase<Merlin.Web.Model.VATLevel>(Constants.EntityTypeNames.VATLevel);
    this.invoiceStatusService = new EnumerationTypeService(Constants.EnumerationTypes.SellingInvoiceStatus);
    this.payementStatusService = new EnumerationTypeService(Constants.EnumerationTypes.PaymentStatus);
    this.highlightSearchTextValueConverter = new HighlightSearchTextValueConverter();
    this.unitService = new ServiceBase<Merlin.Web.Model.Unit>(Constants.EntityTypeNames.Unit);
    this.signatoryService = new ServiceBase<Merlin.Web.Model.InvoiceSignatory>(Constants.EntityTypeNames.InvoiceSignatory);
    this.invoiceService = new ServiceBase<Merlin.Web.Model.SellingInvoice>(Constants.EntityTypeNames.SellingInvoice);
    this.keyDownFunction = (e) => this.handleKeyDown(e);
    document.addEventListener('keydown', this.keyDownFunction)
  }

  getBottomRowRenderer() {
    if (this.invoiceIsDraft)
      return FullWidthCellRenderer;
    else
      return null;
  }

  @computedFrom("entity", "entity.invoiceStatusId")
  public get invoiceIsDraft() {
    return this.entity.invoiceStatusId == Constants.SellingInvoiceStatusId.Draft;
  }

  public async activate(params: any) {
    let id: number = params.param1 ?? params.invoiceId ?? params.creditNoteId ?? params.billingVoucherId;
    await super.activate(params);
    this.appParameter = await this.appParameterService.getEntityById(1);
    if (params.projectId != null) {
      this.isFromProject = true;
    }
    if (id == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity();
      this.entity.invoiceStatus = await this.invoiceStatusService.getEntityById(Constants.SellingInvoiceStatusId.Draft);
      this.entity.payementStatus = await this.payementStatusService.getEntityById(Constants.PaymentStatusId.NotPaid);
      this.entity.defaultVATScheme = (await this.vatService.getEntities(new Predicate("isDefaultVAT", FilterQueryOp.Equals, true)))[0];

      if (this.isFromProject) {
        this.entity.projectId = params.projectId;
      }
      if ((this.router.history as any).fragment.includes("invoices")) {
        this.entity.invoiceTypeId = Constants.SellingInvoiceTypeId.Invoice;
      }
      else if ((this.router.history as any).fragment.includes("credit-notes")) {
        this.entity.invoiceTypeId = Constants.SellingInvoiceTypeId.CreditNote;
      }
      else {
        this.entity.invoiceTypeId = Constants.SellingInvoiceTypeId.BillingVoucher;
      }

      this.thirdPartyService.gridDataSource.queryParameters = { projectId: this.entity.projectId, invoice: true, isSupplier: this.entity.invoiceTypeId == Constants.SellingInvoiceTypeId.BillingVoucher }

      this.entity.invoiceDate = await this.invoiceApiService.getLastInvoiceDate(this.entity.id, this.entity.invoiceTypeId);
      this.entity.nbDecimalForPrice = this.appParameter.nbDecimalForPriceDisplay;
      this.entity.nbDecimalForQuantity = this.appParameter.nbDecimalForQuantityDisplay;

      let thirdParties = await this.thirdPartyService.getEntities(null, null, this.thirdPartyService.gridDataSource.queryParameters);

      if (thirdParties.length == 1) this.entity.client = thirdParties[0];
      this.entity.dueDate = moment(this.entity.invoiceDate).add(this.appParameter.invoicePaymentDelayNbDays, "day").toDate();
    }
    else {
      this.editingMode = EditingModeEnum.Update;
      await this.loadEntity(id);
      this.versionId = this.entity.id;

      if (this.isFromProject) {
        this.thirdPartyService.gridDataSource.queryParameters = { projectId: this.entity.projectId, invoice: true, isSupplier: this.entity.invoiceTypeId == Constants.SellingInvoiceTypeId.BillingVoucher }
      }
      else {
        this.thirdPartyService.gridDataSource.queryParameters = { isSupplier: this.entity.invoiceTypeId == Constants.SellingInvoiceTypeId.BillingVoucher }
      }

      this.setMenuItems();
    }
    this.invoiceService.gridDataSource.queryParameters = { invoiceTypeId: Constants.SellingInvoiceTypeId.Invoice, projectId: this.entity.projectId, invoiceStatusId: Constants.SellingInvoiceStatusId.Validated, thirdPartyId: this.entity.clientId }
    switch (this.entity.invoiceTypeId) {
      case Constants.SellingInvoiceTypeId.CreditNote:
        this.title = this.i18n.tr("sellinginvoice.creditNote");
        break;
      case Constants.SellingInvoiceTypeId.Invoice:
        this.title = this.i18n.tr("sellinginvoice.sellinginvoice");
        break;
      case Constants.SellingInvoiceTypeId.BillingVoucher:
        this.title = this.i18n.tr("sellinginvoice.billingVoucher");
        break;
    }
    this.bindingEngine.propertyObserver(this.entity, "clientId").subscribe((newVal, oldVal) => {
      if (newVal != oldVal) {
        if (!this.isFromProject) {
          if (this.entity.invoiceTypeId == Constants.SellingInvoiceTypeId.BillingVoucher) {
            this.projectService.gridDataSource.queryParameters = { thirdPartyId: newVal, isSupplier: true }
          }
          else {
            this.projectService.gridDataSource.queryParameters = { thirdPartyId: newVal }
          }
          this.entity.projectId = null;
        }
        this.invoiceService.gridDataSource.queryParameters = { invoiceTypeId: Constants.SellingInvoiceTypeId.Invoice, projectId: this.entity.projectId, invoiceStatusId: Constants.SellingInvoiceStatusId.Validated, thirdPartyId: this.entity.clientId };
      }
    });
    this.bindingEngine.propertyObserver(this.entity, "imposedInvoiceDate").subscribe(async (newVal, oldVal) => {
      if (newVal != oldVal) {
        if (!newVal) {
          this.entity.invoiceDate = await this.invoiceApiService.getLastInvoiceDate(this.entity.id, this.entity.invoiceTypeId);
        }
      }
    });
    this.bindingEngine.propertyObserver(this.entity, "projectId").subscribe(async (newVal, oldVal) => {
      if (newVal != oldVal) {
        this.invoiceService.gridDataSource.queryParameters = { invoiceTypeId: Constants.SellingInvoiceTypeId.Invoice, projectId: this.entity.projectId, invoiceStatusId: Constants.SellingInvoiceStatusId.Validated, thirdPartyId: this.entity.clientId };
        if (this.entity.invoiceTypeId == Constants.SellingInvoiceTypeId.CreditNote) {
          this.entity.invoice = null
        }
      }
    });
    UIInternal.subscribe(LINE_HAS_BEEN_ADDED, async () => {
      await this.getMeteringTotalPrices()
    })
  }


  detached() {
    document.removeEventListener('keydown', this.keyDownFunction);
  }

  public async loadEntity(id: number) {
    this.entity = await this.service.getEntityById(id, 'client', 'defaultVATScheme', 'payementStatus', 'invoiceStatus', 'project', 'creditNote', 'invoice');

    this.setMenuItems();
  }

  public async bind() {
    this.vatList = await this.vatService.getAllEntities();
    this.unitList = await this.unitService.getAllEntities();
  }

  private setMenuItems() {
    this.menuItems = [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("sellinginvoice.validateInvoice"),
            icon: "digi-valid",
            handler: async () => {
              await this.validatedInvoice();
            },
            disabled: !this.invoiceIsDraft || this.isCreationMode,
          },
          {
            label: this.entity.invoiceTypeId == Constants.SellingInvoiceTypeId.BillingVoucher ? this.i18n.tr("sellinginvoice.cancelBillingVoucher") : this.i18n.tr("sellinginvoice.cancel"),
            icon: "digi-cross",
            handler: async () => {
              await this.cancelInvoicePrompt();
            },
            disabled: this.entity.invoiceTypeId != Constants.SellingInvoiceTypeId.BillingVoucher && this.entity.invoiceStatusId != Constants.SellingInvoiceStatusId.Validated,
            hidden: this.entity.invoiceTypeId == Constants.SellingInvoiceTypeId.CreditNote
          }
        ]
      },
      {
        group: "2",
        hiddenLabel: true,
        items: [
          {
            label: this.entity.invoiceTypeId == Constants.SellingInvoiceTypeId.BillingVoucher ? this.i18n.tr("sellinginvoice.previewBillingVoucher") : this.i18n.tr("sellinginvoice.previewInvoice"),
            icon: "digi-search",
            handler: async () => {
              await this.previewInvoice();
            },
            disabled: this.entity.invoiceTypeId != Constants.SellingInvoiceTypeId.BillingVoucher && this.entity.invoiceStatusId != Constants.SellingInvoiceStatusId.Validated
          },
          {
            label: this.entity.invoiceTypeId == Constants.SellingInvoiceTypeId.BillingVoucher ? this.i18n.tr("sellinginvoice.sendBillingVoucher") : this.i18n.tr("sellinginvoice.sendInvoice"),
            icon: "digi-mail-send-line",
            handler: () => {
              this.showSendOfferBox();
            },
            disabled: this.entity.invoiceTypeId != Constants.SellingInvoiceTypeId.BillingVoucher && this.entity.invoiceStatusId != Constants.SellingInvoiceStatusId.Validated
          }
        ]
      },
      {
        group: "3",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.delete"),
            icon: "digi-trash",
            handler: () => {
              this.delete()
            },
            disabled: () => {
              return this.entity.invoiceStatusId != Constants.SellingInvoiceStatusId.Draft;
            }
          }
        ]
      },
    ];
  }

  private async validatedInvoice() {
    await this.invoiceApiService.validateInvoice(this.entity.id);
    await this.loadEntity(this.entity.id);
    this.reloadGrid();
  }

  private async cancelInvoicePrompt() {
    if (this.entity.invoiceTypeId == Constants.SellingInvoiceTypeId.BillingVoucher) {
      await this.cancelInvoice(false);
    }
    else {
      let buttonYes: ActionDialogBoxInputParameters =
      {
        label: this.i18n.tr("general.yes", { ns: "common" }),
        title: this.i18n.tr("general.yes", { ns: "common" }),
        theme: 'primary',
        type: 'solid',
        disabled: false,
        fn: (thisBox: DialogBoxViewModel) => {
          thisBox.controller.ok();
        },
        keyboardShortcut: KeyboardShortcut.Enter
      };
      let buttonNo: ActionDialogBoxInputParameters =
      {
        label: this.i18n.tr("general.no", { ns: "common" }),
        title: this.i18n.tr("general.no", { ns: "common" }),
        theme: 'dark',
        type: 'ghost',
        disabled: false,
        fn: (thisBox: DialogBoxViewModel) => {
          thisBox.controller.cancel(false);
        },
        keyboardShortcut: KeyboardShortcut.Escape
      };
      await this.box.showQuestion(this.i18n.tr('sellinginvoice.cancelInvoiceQuestion'), this.i18n.tr('menu.question'), [buttonNo, buttonYes])
        .whenClosed(async result => {
          if (!result.wasCancelled) {
            await this.cancelInvoice(true);
          }
        });
    }
  }

  private async cancelInvoice(createCreditNote: boolean) {
    await this.invoiceApiService.cancelInvoice(this.entity.id, createCreditNote);
    await this.loadEntity(this.entity.id);
    this.reloadGrid();
  }

  private reloadGrid() {
    this.showGrid = false;
    setTimeout(() => {
      this.showGrid = true;
    }, 50);
  }

  afterInitGridOption(gridOptions: GridOptions) {
    if (!this.isCreationMode && this.invoiceIsDraft) {
      gridOptions.noRowsOverlayComponent = VersionOfferOverlayComp;
      gridOptions.noRowsOverlayComponentParams = {
        priceOfferLinesGrid: this,
        noDataLabel: this.i18n.tr("grid.noRowsToShow"),
        meteringApi: this.invoiceApiService
      };
    }
    gridOptions.getRowId = (params) => params.data.id;
    gridOptions.rowClassRules = {
      'ag-row-category-chapter': (params) => {
        return params.data?.lineLevel == 1 &&
          params.data?.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Chapter
      },
      'ag-row-chapter-not-level-1': (params) => {
        return params.data?.lineLevel != 1 &&
          params.data?.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Chapter
      },
      'ag-row-chapter': (params) => {
        return params.data?.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Chapter
      },
      'ag-row-post': (params) => {
        return params.data?.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data
      },
      'ag-row-error': (params) => {
        return this.debugMode &&
          ((params.data?.previousSellingUnitPrice != null && params.data?.sellingUnitPrice != null && params.data?.previousSellingUnitPrice != params.data?.sellingUnitPrice) ||
            (params.data?.previousTotalExclVAT != null && params.data?.totalExclVAT != null && params.data?.previousTotalExclVAT != params.data?.totalExclVAT) ||
            (params.data?.previousTotalVAT != null && params.data?.totalVAT != null && params.data?.previousTotalVAT != params.data?.totalVAT) ||
            (params.data?.previousTotal != null && params.data?.total != null && params.data?.previousTotal != params.data?.total));
      }
    };
  }

  public refreshServerSideRows(ids: number[], refreshAllTable = false, forcePurge = false): Array<RowNode> {
    return this.listTreeData.refreshServerSideRows(ids, refreshAllTable, forcePurge);
  }

  getGroupKey(data) {
    return data.merlinRef;
  }

  public getDataGridColumns(): ColDef[] {
    let defs: CustomColDef[] = [
      {
        colId: "selected",
        headerName: '',
        field: "isSelected",
        width: Constants.AGGridColumnsWidth.IsSelected,
        maxWidth: Constants.AGGridColumnsWidth.IsSelected,
        suppressMovable: true,
        suppressSizeToFit: true,
        suppressAutoSize: true,
        resizable: false,
        cellRenderer: "customHtmlRendererEditor",
        cellClass: (params) => {
          let returnClass = 'ag-cell-center ag-selection';

          // For metering line color, set a style property for the last child line.
          setTimeout(() => {
            if (params.node.alreadyRendered && params.node.lastChild) {
              let rowElementHtml = this.element.querySelector(`[row-id="${params.node.id}"]`);
              if (rowElementHtml != null) {
                let cellSelected = rowElementHtml.querySelector(`[col-id="${params.colDef.colId}"]`)
                if (cellSelected != null) {
                  let spanLine = cellSelected.querySelector(`[class="metering-line-color"]`) as HTMLElement;
                  if (spanLine != null && params.node.parent != null) {
                    let parentTop = params.node.rowTop - params.node.parent.rowTop - params.node.parent.rowHeight;
                    spanLine.style.setProperty("--parentTop", parentTop + "px");
                  }
                }
              }
            }
          }, 100);
          return returnClass;
        },
        cellRendererParams: {
          canComment: false,
          getHtml: (currentThis) => {
            return `<span class="metering-line-color"></span><ui-checkbox style="margin: auto" class="cell-selected" checked.bind="params.data.isSelected" change.delegate='params.change(params, $event)'></ui-checkbox>`;
          },
          change: async (params, event) => {
            this.listTreeData.shiftSelect(params);
            (<RowNode>params.node).setSelected(event.detail.checked);
          }
        },
        pinned: 'left',
        lockPosition: 'left',
        colSpan: (params: ColSpanParams) => {
          if (params?.node?.rowPinned == 'bottom') {
            return 3;
          }
          return 1;
        }
      },
      {
        colId: "merlinRef",
        headerName: this.i18n.tr("sellinginvoiceline.merlinRef"),
        field: "merlinRef",
        type: FieldType.String,
        editable: false,
        suppressMenu: true,
        pinned: "left",
        lockPosition: 'left',
        hideInParameter: true,
        suppressMovable: true,
        sortable: false,
        cellClass: "metering-cell-description ag-cell-column-resize-pinned-left",
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
          canComment: false,
          suppressCount: true,
          suppressDoubleClickExpand: true
        },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },

      {
        colId: "description",
        headerName: this.i18n.tr("sellinginvoiceline.description"),
        field: "description",
        type: FieldType.String,
        editable: (params) => this.canEditCell({ line: params.data, colField: params.colDef.field }),
        suppressMenu: false,
        hideInParameter: true,
        lockVisible: true,
        suppressMovable: true,
        sortable: false,
        cellRenderer: 'customHtmlRendererEditor',
        cellRendererParams: {
          getHtml: (currentThis) => {
            const lineDesc = this.highlightSearchTextValueConverter.toView(currentThis.params.data.description, this.quickFilter);
            let result = `<div class="metering-line-description">`;
            if (currentThis.params.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Comment) {
              result += `<ui-icon icon="digi-information-line"></ui-icon>`;
            }
            if (lineDesc != null) {
              result += `<div class="metering-line-type-description-overflow" title="${lineDesc}">${lineDesc}</div>`;
            }
            result += `</div>`;
            return result;
          },
        },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "unitId",
        headerValueGetter: () => this.i18n.tr("sellinginvoiceline.unitId"),
        field: "unitId",
        valueGetter: (params) => params?.data?.unitTranslatedString,
        valueSetter: (params) => ModuleListTreeData.customEnumsValueSetter(params, this.unitList, ["unitName", "_translation"]),
        type: FieldType.OneToMany,
        filterParams: {
          service: this.unitService,
          customFieldPath: ['unitName', '_translation']
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: this.unitService,
          customFieldPath: ['unitName', '_translation']
        },
        suppressMovable: true,
        suppressMenu: false,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        editable: (params) => this.canEditCell({ line: params.data, colField: params.colDef.field }),
        cellClass: (params) => {
          return 'metering-cell-select';
        },
        cellEditor: "customHtmlRendererEditor",
        cellEditorParams: {
          isPopup: true,
          getHtml: (currentThis) => {
            return `<ui-field no-label style="min-width: ${currentThis.params.column.actualWidth > 200 ? currentThis.params.column.actualWidth : 200}px;"> 
              <ui-select value.one-time="params.data.unitId" on-change.call="params.change(value, params)">
                <option repeat.for="unit of params.unitList" value.bind="unit.id">
                  \${unit.unitName._translation}
                </option>
              </ui-select>
            </ui-field>`;
          },
          unitList: this.unitList,
          change: async (value, params) => {
            params.value = value;
            this.gridOptions?.api?.stopEditing();
            this.gridOptions?.api?.setFocusedCell(params.node.rowIndex, params.colDef.field);
          }
        },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "quantity",
        headerName: this.i18n.tr("sellinginvoiceline.quantity"),
        field: "quantity",
        type: FieldType.Number,
        suppressMenu: false,
        hideInParameter: true,
        lockVisible: true,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params),
        editable: (params) => this.canEditCell({ line: params.data, colField: params.colDef.field }),
        sortable: false,
        cellRenderer: 'customHtmlRendererEditor',
        cellRendererParams: {
          getHtml: (currentThis) => {
            let html = "<div class='metering-price-origin'>";
            let quantity: number = currentThis.params.data['quantity'];
            if (quantity != null) {
              let tooltip: string;
              tooltip = this.i18n.tr("metering.quantityFormula");
              let formattedQuantity = new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "decimal", minimumFractionDigits: 0, maximumFractionDigits: 3 }).format(quantity);
              html += `<span>${(!isNaN(formattedQuantity) ? formattedQuantity : quantity.toString().replace(/[.]/g, ','))}</span>`;
            }
            html += '</div>'
            return html;
          }
        },
        cellEditorParams: {
          fractionDigits: Constants.PrecisionParameter.MaxNbDecimalForQuantityDisplay
        },
      },
      {
        colId: "buyingUnitPrice",
        headerName: this.i18n.tr("sellinginvoiceline.buyingUnitPrice"),
        field: "buyingUnitPrice",
        type: FieldType.Number,
        suppressMenu: false,
        sortable: false,
        hideInParameter: true,
        lockVisible: true,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        valueFormatter: (data) => {
          if (data.data?.[data.colDef.field] != null) {
            return (!isNaN(data.data[data.colDef.field]) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR" }).format(data.data[data.colDef.field]) : data.data[data.colDef.field]);
          }
          return;
        },
        editable: (params) => this.canEditCell({ line: params.data, colField: params.colDef.field }),
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "marginCoefficient",
        headerName: this.i18n.tr("sellinginvoiceline.marginCoefficient"),
        field: "marginCoefficient",
        type: FieldType.Number,
        suppressMenu: false,
        sortable: false,
        hideInParameter: true,
        lockVisible: true,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        editable: (params) => this.canEditCell({ line: params.data, colField: params.colDef.field }),
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "sellingUnitPrice",
        headerName: this.i18n.tr("sellinginvoiceline.sellingUnitPrice"),
        field: "sellingUnitPrice",
        type: FieldType.Number,
        suppressMenu: false,
        sortable: false,
        hideInParameter: true,
        lockVisible: true,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        editable: (params) => this.canEditCell({ line: params.data, colField: params.colDef.field }),
        valueFormatter: (data) => {
          if (data.data?.[data.colDef.field] != null) {
            return (!isNaN(data.data[data.colDef.field]) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR" }).format(data.data[data.colDef.field]) : data.data[data.colDef.field]);
          }
          return;
        },
        cellRenderer: 'customHtmlRendererEditor',
        cellRendererParams: {
          getHtml: (currentThis) => {
            if (this.debugMode)
              return this.listTreeData.getPriceWithTooltipHtml(currentThis.params.data.sellingUnitPrice, currentThis.params.data.previousSellingUnitPrice, this.entity.nbDecimalForPrice);
            else
              return currentThis.params.valueFormatted ?? ""
          },
          maximumFractionDigits: this.entity.nbDecimalForPrice,
        },
        cellClass: (params) => {
          let returnClass = "";
          if (this.debugMode && params.data.previousSellingUnitPrice != null && params.data.sellingUnitPrice != null && params.data.sellingUnitPrice != params.data.previousSellingUnitPrice) {
            returnClass += "previous-price-error "
          }
          return returnClass;
        },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "totalExclVAT",
        headerName: this.i18n.tr("sellinginvoiceline.totalExclVAT"),
        field: "totalExclVAT",
        type: FieldType.Number,
        suppressMenu: false,
        sortable: false,
        hideInParameter: true,
        lockVisible: true,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        valueFormatter: (data) => {
          if (data.data?.[data.colDef.field] != null) {
            return (!isNaN(data.data[data.colDef.field]) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR" }).format(data.data[data.colDef.field]) : data.data[data.colDef.field]);
          }
          return;
        },
        editable: (params) => this.canEditCell({ line: params.data, colField: params.colDef.field }),
        cellRenderer: 'customHtmlRendererEditor',
        cellRendererParams: {
          getHtml: (currentThis) => {
            if (this.debugMode)
              return this.listTreeData.getPriceWithTooltipHtml(currentThis.params.data.totalExclVAT, currentThis.params.data.previousTotalExclVAT, this.entity.nbDecimalForPrice);
            else
              return currentThis.params.valueFormatted ?? ""
          },
          maximumFractionDigits: this.entity.nbDecimalForPrice,
        },
        cellClass: (params) => {
          let returnClass = "";
          if (this.debugMode && params.data.previousTotalExclVAT != null && params.data.totalExclVAT != null && params.data.totalExclVAT != params.data.previousTotalExclVAT) {
            returnClass += "previous-price-error "
          }
          return returnClass;
        },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "totalVAT",
        headerName: this.i18n.tr("sellinginvoiceline.totalVAT"),
        field: "totalVAT",
        type: FieldType.Number,
        suppressMenu: false,
        sortable: false,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        editable: (params) => this.canEditCell({ line: params.data, colField: params.colDef.field }),
        valueFormatter: (data) => {
          if (data.data?.[data.colDef.field] != null) {
            return (!isNaN(data.data[data.colDef.field]) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR" }).format(data.data[data.colDef.field]) : data.data[data.colDef.field]);
          }
          return;
        },
        cellRenderer: 'customHtmlRendererEditor',
        cellRendererParams: {
          getHtml: (currentThis) => {
            if (this.debugMode)
              return this.listTreeData.getPriceWithTooltipHtml(currentThis.params.data.totalVAT, currentThis.params.data.previousTotalVAT, this.entity.nbDecimalForPrice);
            else
              return currentThis.params.valueFormatted ?? ""
          },
          maximumFractionDigits: this.entity.nbDecimalForPrice,
        },
        cellClass: (params) => {
          let returnClass = "";
          if (this.debugMode && params.data.previousTotalVAT != null && params.data.totalVAT != null && params.data.totalVAT != params.data.previousTotalVAT) {
            returnClass += "previous-price-error "
          }
          return returnClass;
        },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "total",
        headerName: this.i18n.tr("sellinginvoiceline.total"),
        field: "total",
        type: FieldType.Number,
        suppressMenu: false,
        sortable: false,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        editable: (params) => this.canEditCell({ line: params.data, colField: params.colDef.field }),
        valueFormatter: (data) => {
          if (data.data?.[data.colDef.field] != null) {
            return (!isNaN(data.data[data.colDef.field]) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR" }).format(data.data[data.colDef.field]) : data.data[data.colDef.field]);
          }
          return;
        },
        cellRenderer: 'customHtmlRendererEditor',
        cellRendererParams: {
          getHtml: (currentThis) => {
            if (this.debugMode)
              return this.listTreeData.getPriceWithTooltipHtml(currentThis.params.data.total, currentThis.params.data.previousTotal, this.entity.nbDecimalForPrice);
            else
              return currentThis.params.valueFormatted ?? ""
          },
          maximumFractionDigits: this.entity.nbDecimalForPrice,
        },
        cellClass: (params) => {
          let returnClass = "";
          if (this.debugMode && params.data.previousTotal != null && params.data.total != null && params.data.total != params.data.previousTotal) {
            returnClass += "previous-price-error "
          }
          return returnClass;
        },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "vATSchemeId",
        headerValueGetter: () => this.i18n.tr("sellinginvoiceline.vATSchemeId"),
        field: "vATSchemeId",
        valueGetter: (params) => params?.data?.vatCode,
        valueSetter: (params) => ModuleListTreeData.customEnumsValueSetter(params, this.vatList, ["code"]),
        type: FieldType.OneToMany,
        filterParams: {
          service: this.vatService,
          customFieldPath: ["code"]
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: this.vatService,
          customFieldPath: ["code"]
        },
        suppressMenu: false,
        sortable: false,
        hideInParameter: true,
        lockVisible: true,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        editable: (params) => this.canEditCell({ line: params.data, colField: params.colDef.field }),
        cellClass: (params) => {
          return 'metering-cell-select';
        },
        cellEditor: "customHtmlRendererEditor",
        cellEditorParams: {
          isPopup: true,
          getHtml: (currentThis) => {
            return `<ui-field no-label style="min-width: ${currentThis.params.column.actualWidth > 200 ? currentThis.params.column.actualWidth : 200}px;"> 
                <ui-select value.one-time="params.data.vatSchemeId" on-change.call="params.change(value, params)" allow-clear="true">
                  <option repeat.for="vat of params.vatList" value.bind="vat.id">
                    \${vat.code}
                  </option>
                </ui-select>
              </ui-field>`;
          },
          vatList: this.vatList,
          change: async (value, params) => {
            params.value = value;
            this.gridOptions?.api?.stopEditing();
            this.gridOptions?.api?.setFocusedCell(params.node.rowIndex, params.colDef.field);
          }
        },
      }
    ];

    if (this.getGridMenuItems != null) {
      defs.unshift({
        colId: "menuIems",
        headerName: "",
        field: "",
        maxWidth: Constants.AGGridColumnsWidth.IsSelected,
        minWidth: Constants.AGGridColumnsWidth.IsSelected,
        suppressMovable: true,
        cellRendererParams: {
          canComment: false,
          i18n: this.i18n,
          gridOptions: this.gridOptions,
          router: this.router,
          menuItems: (params: ICellRendererParams) => this.getGridMenuItems != null ? this.getGridMenuItems(params) : [],
        },
        cellRenderer: "customButtonRenderer",
        suppressColumnsToolPanel: true,
        resizable: false,
        filter: false,
        pinned: "left",
        lockPosition: 'left'
      });
    }

    defs.forEach(def => {
      if (def.headerValueGetter != null) {
        def.headerName = def.headerTooltip = (<any>def).headerValueGetter();
        def.sortable = false;
      }
    });

    return defs;
  }
  public getGridMenuItems(params: ICellRendererParams): Array<IMenuGroup> {
    if (!this.invoiceIsDraft) return
    return [
      this.meteringMenuItems.getAdd(params, this as any, this.invoiceApiService, this.entity.id.toString(), this.entity.id.toString(), this.entity.id.toString(), this.computeMeteringTotalPrice, this, true),
      {
        group: "1",
        hiddenLabel: true,
        items: [
          this.meteringMenuItems.getDuplicate(params, this.invoiceApiService, this as any, this.entity.id.toString(), this.computeMeteringTotalPrice, this),
          this.meteringMenuItems.getCopy(params, this as any, Constants.LocalStorageName.SellingInvoice),
          this.meteringMenuItems.getPaste(params, Constants.LocalStorageName.SellingInvoice, this as any, this.invoiceApiService, this.entity.id.toString(), this.computeMeteringTotalPrice, this),
          this.meteringMenuItems.getMove(params, this.invoiceApiService, this as any, false, this.entity.id.toString())
        ]
      },
      {
        group: "5",
        hiddenLabel: true,
        items: [
          this.meteringMenuItems.getCommentCell(params, this.commentService, this as any)
        ]
      },
      {
        group: "4",
        hiddenLabel: true,
        items: [
          this.meteringMenuItems.getDeleteLine(params, this.invoiceApiService, this as any, this.entity.id.toString(), this.computeMeteringTotalPrice, this)
        ]
      }
    ];
  }
  private getColSpanOfColDef(params: ColSpanParams) {
    if (params.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Comment) {
      if (params.column.isPinned()) {
        return 1;
      }
      return params.columnApi.getColumns().filter(x => !x.isPinned()).length;
    }
    return 1;
  }

  timeoutId: NodeJS.Timeout | null = null;
  events: {
    lineId: number,
    propertyName: string,
    propertyValue: any
  }[] = [];
  async onCellValueChanged(event: CellValueChangedEvent) {
    if (event?.colDef == null) return;
    // Patch model
    let colField: string = event.colDef.field;
    let value: any = event.data[colField];

    this.events.push({ lineId: parseInt(event.data.id), propertyName: colField, propertyValue: event.data[colField] });

    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.timeoutId = setTimeout(async () => {
      // Call api
      if (this.events.length == 0) return;

      let patchEvents = this.events

      this.events = [];
      this.timeoutId = null;

      let result = patchEvents.length == 1 ?
        await this.invoiceApiService.patch(this.entity.id, patchEvents[0].lineId, patchEvents[0].propertyName, patchEvents[0].propertyValue) :
        await this.invoiceApiService.bulkPatch(this.entity.id, patchEvents);
      // Manage api result: all modified entities
      if (result != null && result.length) {
        this.refreshVisibleNodes(result);
        this.cellValueChanged(colField);
      } else {
        toastr.error(this.i18n.tr("metering.errorSave"));
      }
    }, 600);
  }

  public async refreshVisibleNodes(ids: number[], flashCells = true): Promise<Array<RowNode>> {
    return await this.listTreeData.refreshVisibleNodes(ids, flashCells);
  }

  public cellValueChanged(colField: string) {
    if (colField == null || colField.trim().length == 0) return;
    switch (colField) {
      case "quantity":
      case "buyingUnitPrice":
      case "vATSchemeId":
      case "marginCoefficient":
      case "sellingUnitPrice":
      case "totalExclVAT":
        this.getMeteringTotalPrices();
        break;
    }

  }

  public async getMeteringTotalPrices() {
    await this.loadEntity(this.entity.id);
  }

  public async computeMeteringTotalPrice(id: string, dataLineApiService: IDataLineApiService, _this: any) {
    _this.entity = await _this.service.getEntityById(parseInt(id), 'client', 'defaultVATScheme', 'payementStatus', 'invoiceStatus', 'project');
  }

  private canEditCell(cell: { line: any, colField: string }): boolean {
    if (this.invoiceIsDraft) {
      switch (cell.colField) {
        case "description":
          return true
        case "quantity":
        case "buyingUnitPrice":
        case "vATSchemeId":
        case "unitId":
        case "sellingUnitPrice":
        case "totalExclVAT":
          return cell.line.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data

        case "marginCoefficient":
          return cell.line.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data || cell.line.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Chapter
      }
    }
    return false;
  }
  public triggerExpand() {
    this.screenExpand = !this.screenExpand;
  }
  private async recomputeLines() {
    let previousTotalPrices: MeteringTotalPrices = {
      totalSelling: this.entity.total,
      totalBuying: this.entity.totalExclVAT,
      percentageMargin: 0,
      margin: 0
    }
    this.gls.allow(true, 0);
    await this.invoiceApiService.recomputeLines(this.entity.id);
    await this.loadEntity(this.entity.id)
    let newTotalPrices: MeteringTotalPrices = {
      totalSelling: this.entity.total,
      totalBuying: this.entity.totalExclVAT,
      percentageMargin: 0,
      margin: 0
    }
    await this.dialogService.open({
      viewModel: DebugTotalPricesError,
      model: {
        previousTotalPrices: previousTotalPrices,
        newTotalPrices: newTotalPrices,
        nbDecimalForPriceDisplay: this.entity.nbDecimalForPrice
      },
      lock: true,
      keyboard: false,
      rejectOnCancel: true,
      position(dialogContainer, dialogOverlay?) {
        dialogContainer.classList.add("dialog-container-debug-total-price");
      },
    });
    this.refreshServerSideRows(null, true, true);
  }
  private showSendOfferBox() {
    this.box.showCustomDialog(
      SendOffer,
      this.entity.id,
      this.i18n.tr('sellinginvoice.sendInvoice'),
      {
        canSave: false,
        size: 'lg',
        model: {
          generationParams: {
            projectId: this.entity.projectId,
            companyIds: [this.entity.clientId],
            coverLetterContentHtml: this.entity.coverLetterContentHtml,
            annexContentHtml: this.entity.annexContentHtml,
            coverLetterContext: this.entity.invoiceTypeId == Constants.SellingInvoiceTypeId.BillingVoucher ? Constants.ContentTypeId.BillingVoucherCoverLetter : Constants.ContentTypeId.InvoiceCoverLetter,
            annexContext: this.entity.invoiceTypeId == Constants.SellingInvoiceTypeId.BillingVoucher ? Constants.ContentTypeId.BillingVoucherAnnex : Constants.ContentTypeId.InvoiceAnnex,
            emailContext: this.entity.invoiceTypeId == Constants.SellingInvoiceTypeId.BillingVoucher ? Constants.EmailContextId.BillingVoucher : Constants.EmailContextId.Invoice,
            languageId: this.entity.project.communicationLanguageId,
            signatoryIds: this.entity.invoiceSignatories.map(x => x.signatoryUserId),
            previewRequestParams: {
              projectId: this.entity.projectId,
              invoiceId: this.entity.id
            },
            afterStep2: async (data: ReturnParams) => {
              await this.handleGenerationReturnParams(data);
            },
            items: [
              {
                id: 1,
                name: this.i18n.tr("priceofferversion.pdf"),
                items: [
                  {
                    id: 1,
                    icon: "digi-file-sync",
                    title: this.i18n.tr("priceofferversion.generate"),
                    description: this.i18n.tr("priceofferversion.pfdHasInternalModel"),
                    selected: true,
                    disabled: false,
                    handler: async (data: GenerationApiParams) => {
                      return await this.reportApiService.getInvoicePdf(this.entity.id, data.selectedRefToDisplay);
                    }
                  }
                ]
              }
            ]
          }
        }
      }
    ).whenClosed(async (result) => {
      if (!result.wasCancelled) {
        await this.handleGenerationReturnParams(result.output);
      }
    });
  }
  private async handleGenerationReturnParams(data: ReturnParams) {
    this.entity.annexContentHtml = data.annexContentHtml;
    this.entity.coverLetterContentHtml = data.coverLetterContentHtml;

    await this.service.saveEntity(this.entity, true);

    if (this.entity.invoiceSignatories.length > 0)
      await this.signatoryService.deleteEntities(this.entity.invoiceSignatories, false, null, true);

    data.signatoryIds.forEach(async (signatoryId) => {
      this.entity.invoiceSignatories.push(await this.signatoryService.createEntity({ signatoryUserId: signatoryId, invoiceId: this.entity.id }));
    });
    await this.signatoryService.saveEntities(this.entity.invoiceSignatories, true);
  }
  @computedFrom("entity")
  get getInvoiceLabel(): string {
    return this.entity.invoiceTypeId == Constants.SellingInvoiceTypeId.CreditNote ? this.entity.invoice?.invoiceNumber : this.entity.creditNote?.invoiceNumber
  }

  public goToInvoice() {
    if (this.entity.invoiceTypeId == Constants.SellingInvoiceTypeId.CreditNote) {
      window.open(`invoices/${this.entity.invoice.id}`, "_blank");
    }
    else {
      window.open(`credit-notes/${this.entity.creditNote.id}`, "_blank");
    }
  }
  private async previewInvoice() {
    this.gls.allow();
    var result = await this.reportApiService.getPreviewInvoicePdf(this.entity.id);
    this.fileHelper.previewFile(result);
  }
  private handleKeyDown(e) {
    if (e.key == "Delete") {
      this.meteringMenuItems.menuDeleteLine.deleteLine(0, this.invoiceApiService, this as any, this.entity.id.toString(), this.computeMeteringTotalPrice, this)
    }
  }
}
