import { RowNode } from 'ag-grid-community';
import { PriceOfferLineCategory, AdditionalWorkTypeId } from '../../../constants';
import { Router } from 'aurelia-router';
import { BindingEngine, TaskQueue, autoinject, computedFrom, observable } from "aurelia-framework";
import { CustomLogger, EditingModeEnum, EntityDetailViewModelBase, EnumerationTypeService, GlobalLoaderService, IMenuGroup, PictureHelper, ServiceBase, Various } from "digiwall-lib";
import { Merlin } from "generated";
import * as Constants from '../../../constants';
import { ClientAdditionalWorkService } from 'services/client-addition-work-service';
import { ColumnVisible } from 'module-list-tree-data/module-list-tree-data';
import { PriceOfferLinesGrid } from 'price-offer-lines/price-offer-lines-grid';
import { IDataLineApiService, MeteringTotalPrices } from 'services/i-data-line-api-service';
import { AddItems } from 'projects/quotes/add-items/add-items';
import { RowGroupOpenedEvent } from 'ag-grid-community';
import { DataFormat } from 'select2';
import { AdditionalWorkClone } from './additional-work-clone/additional-work-clone';
import { SendOffer, ReturnParams, GenerationApiParams } from 'projects/quotes/send-offers/send-offer';
import { ReportApiService } from 'services/report-api-service';
import moment from 'moment';
import { UpdateItemType } from 'projects/quotes/update-items/update-items';
import { MeteringMenuItems } from 'resources/metering/metering-menu-items';
import { AdditionalWorkFullWidthCellRenderer, AdditionalWorkOverlayComp } from './additional-work-overlay-comp';

@autoinject
export class AdditionalWork extends EntityDetailViewModelBase<Merlin.Web.Model.AdditionalWork> {
  @computedFrom('editingMode', 'entity')
  get documentTitle() {
    if (this.isCreationMode) {
      return '';
    }
    return this.i18n.tr('clientadditionalwork.title') + ' : ' + this.entity.numAdditionalWork;
  }
  ressourceName = '';
  projectId: any;
  additionalWorkId: any;

  priceOfferLinesGrid: PriceOfferLinesGrid;

  private footer: MeteringTotalPrices;
  selectedAllFirstTime: boolean = false;
  private project: Merlin.Web.Model.Project;

  private signatoryService: ServiceBase<Merlin.Web.Model.AdditionalWorkVersionSignatory>;

  public showGrid: boolean = true;

  public screenExpand: boolean;
  public columnVisible: Array<ColumnVisible> =
    [
      { colId: "menuIems", visibility: true },
      { colId: "selected", visibility: true },
      { colId: "merlinRef", visibility: true },
      { colId: "merlinRefMetering", visibility: false },
      { colId: "merlinRefContext", visibility: true },
      { colId: "lineDescription", visibility: true },
      { colId: "lineRef", visibility: true },
      { colId: "originalMeasurementXlsLineNumber", visibility: true },
      { colId: "sheetIndex", visibility: true },
      { colId: "importFileName", visibility: true },
      { colId: "quantityTypeId", visibility: true },
      { colId: "unitId", visibility: true },
      { colId: "proposedQuantity", visibility: true },
      { colId: "proposedQuantityFormula", visibility: true },
      { colId: "priceOfferLineFileCount", visibility: true },
      { colId: "additionalDescriptionMultiline", visibility: true },
      { colId: "requestedQuantity", visibility: true },
      { colId: "acceptedRequestedQuantity", visibility: true },
      { colId: "dataLineDeliveries", visibility: true },
      { colId: "dataLineActivities", visibility: true },
      { colId: "buyingUnitPrice", visibility: true },
      { colId: "definedSellingUnitPrice", visibility: true },
      { colId: "definedDirectMarginPrice", visibility: true },
      { colId: "buyingUnitPrice", visibility: false },
      { colId: "sellingUnitPriceWithIndirectCostFromPAR", visibility: false },
      { colId: "marginCoefficient", visibility: true },
      { colId: "totalBuyingPrice", visibility: true },
      { colId: "totalSellingPrice", visibility: true },
      { colId: "tags", visibility: false },
      { colId: "features", visibility: true },
      { colId: "supplierId", visibility: true },
      { colId: "supplierPrice", visibility: false },
      { colId: "supplierQuantity", visibility: false },
      { colId: "supplierQuantityTypeId", visibility: false },
      { colId: "supplierTotal", visibility: false },
      { colId: "supplierComment", visibility: false },
      { colId: "acceptedPriceOfferBuyingUnitPrice", visibility: true },
      { colId: "buyingClientValidationStatusId", visibility: true },
      { colId: "buyingClientValidationDeadlineDate", visibility: true },
      { colId: "buyingClientValidationDate", visibility: true },
      { colId: "deliveryDeadlineDate", visibility: true },
      { colId: "unitQuantityDetailItemComposed", visibility: true },
      { colId: "unitQuantityFormulaDetailItemComposed", visibility: true }
    ];

  private menuItems: Array<IMenuGroup>;
  private projectService: ServiceBase<Merlin.Web.Model.Project>

  constructor(router: Router, logger: CustomLogger, private clientAdditionalWorkService: ClientAdditionalWorkService, private taskQueue: TaskQueue, private bindingEngine: BindingEngine, private reportApiService: ReportApiService, private gls: GlobalLoaderService, private meteringMenuItems: MeteringMenuItems, private fileHelper: PictureHelper) {
    super(router, logger);
    super.initialize(new ServiceBase<Merlin.Web.Model.AdditionalWork>(Constants.EntityTypeNames.AdditionalWork));
    this.projectService = new ServiceBase<Merlin.Web.Model.Project>(Constants.EntityTypeNames.Project);

    this.signatoryService = new ServiceBase<Merlin.Web.Model.AdditionalWorkVersionSignatory>(Constants.EntityTypeNames.AdditionalWorkVersionSignatory);
  }

  attached() {
    if (this.editingMode == EditingModeEnum.Create) {
      if (this.priceOfferLinesGrid == null) {
        this.bindingEngine.propertyObserver(this, 'priceOfferLinesGrid').subscribe((newValue, oldValue) => {
          if (newValue != oldValue && newValue != null) this.observablePriceOfferLinesGrid();
        })
      } else {
        this.observablePriceOfferLinesGrid();
      }
    }
  }

  getOverlayComponent() {
    if (this.version.statusId != Constants.ClientAdditionalWorkStatusTypeId.Draft || this.editingMode == EditingModeEnum.Create)
      return null
    return AdditionalWorkOverlayComp;
  }
  getBottomRowRenderer() {
    if (this.version.statusId != Constants.ClientAdditionalWorkStatusTypeId.Draft || this.editingMode == EditingModeEnum.Create)
      return null;
    return AdditionalWorkFullWidthCellRenderer;
  }

  observablePriceOfferLinesGrid() {
    if (this.priceOfferLinesGrid.listTreeData != null) {
      this.observableListTreeData()
    }
    this.bindingEngine.propertyObserver(this.priceOfferLinesGrid, "listTreeData").subscribe((newValue, oldValue) => {
      if (newValue != oldValue && this.priceOfferLinesGrid.listTreeData != null) {
        this.observableListTreeData()
      }
    });

  }

  observableListTreeData() {
    this.bindingEngine.propertyObserver(this.priceOfferLinesGrid.listTreeData, "dataLines").subscribe((newValue, oldValue) => {
      if (newValue != oldValue && !this.selectedAllFirstTime) {
        this.priceOfferLinesGrid.listTreeData.dataLines.forEach(line => line.isSelected = true);
        this.clientAdditionalWorkService.dataLines = this.priceOfferLinesGrid.listTreeData.dataLines;
        this.selectedAllFirstTime = true;
      }
    });
    this.bindingEngine.propertyObserver(this.priceOfferLinesGrid.listTreeData, 'flattenList').subscribe((newVal, oldVal) => {
      if (newVal) {
        setTimeout(() => {
          this.priceOfferLinesGrid.listTreeData.gridOptions.api.forEachNode(node => {
            node.setSelected(node.data.isSelected)
          })
        }, 100);
      }
    })
  }

  onRowGroupOpened(event: RowGroupOpenedEvent<any>) {
    if (event.node.expanded) {
      setTimeout(() => {
        (<RowNode[]>(<any>event).node.childStore?.allRowNodes).forEach((node) => {
          node.setSelected(node.data.isSelected);
        });
      }, 100);

    }
  }

  public triggerExpand() {
    this.screenExpand = !this.screenExpand;
  }

  @observable
  private selectedVersion: any;

  private version: Merlin.Web.Model.ClientAdditionalWorkVersion;
  private hasAcceptedVersion: boolean;
  private hasDraftVersion: boolean;

  async activate(params) {
    await super.activate(params);
    this.projectId = params.projectId;
    this.project = await new ServiceBase<Merlin.Web.Model.Project>(Constants.EntityTypeNames.Project).getEntityById(parseInt(this.projectId));
    this.clientAdditionalWorkService.projectId = this.projectId;
    this.clientAdditionalWorkService.dataLines = null;
    this.clientAdditionalWorkService.clientAdditionalWorkId = null;
    this.clientAdditionalWorkService.clientAdditionalWorkVersionId = null;
    if (params.additionalWorkId == Various.NewId) {
      this.additionalWorkId = null;
      this.editingMode = EditingModeEnum.Create;
      let parameter = await new ServiceBase<Merlin.Web.Model.ApplicationParameter>(Constants.EntityTypeNames.ApplicationParameter).firstEntity();
      this.version = await new ServiceBase<Merlin.Web.Model.ClientAdditionalWorkVersion>(Constants.EntityTypeNames.ClientAdditionalWorkVersion).createEntity({
        validUntilDate: moment().add(parameter.additionalWorkValidityDelayNbDays, "days").toDate(),
        additionalDelayNbDays: parameter.additionalWorkAdditionalDelayNbDays
      });
      this.version.entityAspect.setDetached();
    } else {
      this.editingMode = EditingModeEnum.Update;
      this.additionalWorkId = params.additionalWorkId;
      await this.loadEntity(params.clientAdditionalWorkVersionId);
    }
  }

  async loadEntity(versionId) {
    if (this.entity != null) {
      this.entity.entityAspect.setDetached();
    }
    this.selectedVersion = 0;
    this.entity = await this.service.getEntityById(this.additionalWorkId, 'clientAdditionalWorkVersions.status', 'clientAdditionalWorkVersions.additionalWorkVersionSignatories');
    await this.getHasAcceptedVersion();
    await this.getHasDraftVersion();
    if (versionId != null && versionId != Various.NewId) {
      this.selectedVersion = versionId;
    } else {
      if (this.hasAcceptedVersion) {
        this.selectedVersion = this.entity.clientAdditionalWorkVersions[this.entity.clientAdditionalWorkVersions.findIndex(x => x.statusId == Constants.ClientAdditionalWorkStatusTypeId.Validated)].id;
      }
      else {
        this.selectedVersion = this.entity.clientAdditionalWorkVersions.last().id;
      }
    }
    this.clientAdditionalWorkService.clientAdditionalWorkId = this.selectedVersion;
    await this.getMeteringTotalPrices();
    this.setMenuItems();
  }

  async selectedVersionChanged(newValue, oldValue) {
    if (newValue != oldValue) {
      if (typeof newValue === "object") {
        newValue = parseInt(newValue.id);
      }
      this.clientAdditionalWorkService.clientAdditionalWorkVersionId = newValue;
      this.priceOfferLinesGrid?.saveSettingAndReload();
      this.version = this.entity.clientAdditionalWorkVersions.find(x => x.id == newValue);
      await this.getMeteringTotalPrices();
    }
  }

  async deactivate() {
    await this.priceOfferLinesGrid?.deactivate()
  }

  private async setMenuItems() {
    this.menuItems = [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("versionmetering.addVersion"),
            icon: "digi-add-circle-line",
            disabled: () => {
              return this.hasAcceptedVersion || this.hasDraftVersion;
            },
            handler: () => {
              this.addVersion(this.projectId);
            }
          },
          {
            label: this.i18n.tr("realizationmetering.removeLinks"),
            icon: "digi-minus-circle",
            disabled: () => {
              return this.version.statusId != Constants.ClientAdditionalWorkStatusTypeId.Draft;
            },
            handler: async () => {
              await this.removeLinks(this.getSelectedPost().map(x => x.id));
            }
          },
        ]
      },
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("clientadditionalwork.changeStatus"),
            icon: "digi-exchange-line",
            items: [
              {
                label: this.i18n.tr("clientadditionalwork.accept"),
                disabled: () => {
                  return this.hasAcceptedVersion;
                },
                handler: async () => {
                  await this.setStatus(Constants.ClientAdditionalWorkStatusTypeId.Validated);
                }
              },
              {
                label: this.i18n.tr("clientadditionalwork.sent"),
                disabled: () => {
                  return this.version.statusId == Constants.ClientAdditionalWorkStatusTypeId.Validated || this.version.statusId == Constants.ClientAdditionalWorkStatusTypeId.Rejected;
                },
                handler: async () => {
                  await this.setStatus(Constants.ClientAdditionalWorkStatusTypeId.Sent);
                }
              },
              {
                label: this.i18n.tr("clientadditionalwork.reject"),
                disabled: () => {
                  return this.version.statusId == Constants.ClientAdditionalWorkStatusTypeId.Validated;
                },
                handler: async () => {
                  await this.setStatus(Constants.ClientAdditionalWorkStatusTypeId.Rejected);
                }
              },

            ]
          },
        ]
      },
      {
        group: "2",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("clientadditionalwork.previewAdditionnalWork"),
            icon: "digi-search",
            handler: async () => {
              await this.previewAdditionnalWork();
            },
          },
          {
            label: this.i18n.tr('clientadditionalwork.sendAdditionnalWork'),
            icon: "digi-mail-send-line",
            handler: () => {
              this.showSendOfferBox();
            }
          }
        ]
      }

    ];
  }

  private async setStatus(statusId: number) {
    let setStatusResponse = await this.clientAdditionalWorkService.setStatus(this.version.id, statusId);
    if (setStatusResponse) {
      this.showGrid = false;
      await this.loadEntity(this.selectedVersion);
      setTimeout(() => {
        this.showGrid = true;
      }, 100);
    }
  }

  async addPost(projectId: any) {
    this.priceOfferLinesGrid.gridOptions.api.showLoadingOverlay();

    let ids = await this.clientAdditionalWorkService.createLine(this.projectId, null, Constants.PriceOfferLineCategory.Data, Constants.PriceOfferLineMoveAction.Into);
    this.priceOfferLinesGrid.gridOptions.api.hideOverlay();

    this.priceOfferLinesGrid.refreshServerSideRows(ids, true);
  }

  private async addItems(projectId: number) {
    await this.box.showCustomDialog(AddItems, projectId, null, {
      model: {
        action: Constants.PriceOfferLineMoveAction.Into,
        projectId: this.projectId,
        api: this.clientAdditionalWorkService,
        parent: null
      },
      size: "xl"
    }).whenClosed((result) => {
      if (result.output) {
        this.priceOfferLinesGrid.refreshServerSideRows(result.output, true);
        this.getMeteringTotalPrices();
      }
    });
  }

  private async addVersion(projectId: number) {
    let result = await this.dialogService.open({
      viewModel: AdditionalWorkClone,
      model: {
        projectId: projectId,
        additionalWorkId: this.entity.id,
        additionalWorkVersionId: this.selectedVersion
      },
      lock: true,
      keyboard: false,
      rejectOnCancel: true,
      position(dialogContainer, dialogOverlay?) {
        dialogContainer.classList.add("dialog-container-add-work-quotation-version");
      },
    });
    if (!result.wasCancelled) {
      const versionId = (await result.closeResult).output;
      await this.loadEntity(versionId);
    }
    return null;
  }

  getSelectedPost() {
    return this.priceOfferLinesGrid.listTreeData.gridOptions.api.getSelectedRows().filter(x => x.isSelected && x.priceOfferLineCategoryId == PriceOfferLineCategory.Data && (x.isDetailedLine == null || !x.isDetailedLine) && (x.isSubItem == null || !x.isSubItem));
  }

  private async getMeteringTotalPrices() {
    this.footer = await this.clientAdditionalWorkService.getMeteringTotalPrices(this.selectedVersion);
  }

  async getHasAcceptedVersion() {
    this.hasAcceptedVersion = await this.clientAdditionalWorkService.getHasAcceptedVersion(this.additionalWorkId);
  }
  async getHasDraftVersion() {
    this.hasDraftVersion = await this.clientAdditionalWorkService.getHasDraftVersion(this.additionalWorkId);
  }

  getGridMenuItems(params) {
    if (this.isCreationMode || params.node?.data == null || params.node?.data.isDetailedLine || params.node?.data.isSubItem)
      return [];
    if (this.entity)
      return [
        {
          group: "1",
          hiddenLabel: true,
          items: [
            {
              label: this.i18n.tr("realizationmetering.addPost"),
              icon: "digi-flashlight-line",
              disabled: () => {
                return this.version.statusId != Constants.ClientAdditionalWorkStatusTypeId.Draft;
              },
              items: [
                {
                  label: this.i18n.tr("versionmetering.onTopOfLine"),
                  handler: async () => {
                    this.addPostLine(parseInt(params.node.id), Constants.PriceOfferLineMoveAction.Up);
                  },
                  icon: "digi-arrow-up-line"
                },
                {
                  label: this.i18n.tr("versionmetering.belowLine"),
                  handler: async () => {
                    this.addPostLine(parseInt(params.node.id), Constants.PriceOfferLineMoveAction.Down);
                  },
                  icon: "digi-arrow-down-line"
                },
              ]
            },
            {
              label: this.i18n.tr("versionmetering.addItem"),
              icon: "digi-database-2-line",
              disabled: () => {
                return this.version.statusId != Constants.ClientAdditionalWorkStatusTypeId.Draft;
              },
              items: [
                {
                  label: this.i18n.tr("versionmetering.onTopOfLine"),
                  handler: async () => {
                    this.addItem(parseInt(params.node.id), Constants.PriceOfferLineMoveAction.Up);
                  },
                  icon: "digi-arrow-up-line"
                },
                {
                  label: this.i18n.tr("versionmetering.belowLine"),
                  handler: async () => {
                    this.addItem(parseInt(params.node.id), Constants.PriceOfferLineMoveAction.Down);
                  },
                  icon: "digi-arrow-down-line"
                },
              ]
            },
            this.meteringMenuItems.getUpdateItems(params, this.clientAdditionalWorkService, this.priceOfferLinesGrid, this.projectId, this.additionalWorkId, this.columnVisible, UpdateItemType.Other, this.router.currentInstruction.config.moduleId, false, this.computeMeteringTotalPrice, this),
            {
              label: this.i18n.tr("realizationmetering.freeLine"),
              icon: "digi-minus-circle",
              disabled: () => params.node.data.priceOfferLineCategoryId != PriceOfferLineCategory.Data || this.entity.clientAdditionalWorkVersions.length > 1 || this.version.statusId != Constants.ClientAdditionalWorkStatusTypeId.Draft,
              handler: async () => {
                await this.freeLine(params.node.data.id);
              }
            },
            {
              label: this.i18n.tr("realizationmetering.deleteLine"),
              icon: "digi-minus-circle",
              disabled: () => params.node.data.priceOfferLineCategoryId != PriceOfferLineCategory.Data || this.version.statusId != Constants.ClientAdditionalWorkStatusTypeId.Draft,
              handler: async () => {
                await this.deleteLine(params.node.data.id);
              }
            }
          ]
        }

      ];
  }

  async addPostLine(lineId: number, action: Constants.PriceOfferLineMoveAction) {
    let ids = await this.clientAdditionalWorkService.addPostLine(lineId, action);
    this.priceOfferLinesGrid.refreshServerSideRows(ids, true);
  }

  async addItem(targetId: number, action: Constants.PriceOfferLineMoveAction) {
    let targetNode = this.priceOfferLinesGrid.gridOptions.api.getRowNode(targetId.toString());
    if (!targetNode) {
      console.warn('Target node not found!');
      return;
    }

    await this.box.showCustomDialog(AddItems, targetId, null, {
      model: {
        action: action,
        projectId: this.projectId,
        api: this.clientAdditionalWorkService,
        clientAdditionalWorkVersionId: this.selectedVersion,
        parent: action == Constants.PriceOfferLineMoveAction.Into && targetNode.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data ? targetNode.data : null
      }, size: "xl"
    }).whenClosed(async (result) => {
      if (result.output) {
        this.priceOfferLinesGrid.refreshServerSideRows(result.output, true);
        if (action == Constants.PriceOfferLineMoveAction.Into) {
          targetNode.setExpanded(true);
        }
        await this.getMeteringTotalPrices();
      }
    });
    // this.priceOfferLinesGrid.refreshServerSideRows(ids);
  }

  async freeLine(lineId: number) {
    let ids = await this.clientAdditionalWorkService.freeLine(lineId);
    this.priceOfferLinesGrid.refreshServerSideRows(ids, true);
    await this.getMeteringTotalPrices();
  }

  async deleteLine(lineId: number) {
    let ids = await this.clientAdditionalWorkService.deleteLine(lineId);
    this.priceOfferLinesGrid.refreshServerSideRows(ids, true);
    await this.getMeteringTotalPrices();
  }

  async removeLinkClientAdditionalWork(lineId: number) {
    await this.clientAdditionalWorkService.removeLinkClientAdditionalWork(this.projectId, this.additionalWorkId, lineId);
    this.priceOfferLinesGrid.refreshServerSideRows([], true);
    await this.getMeteringTotalPrices();
  }

  async removeLinks(lineIds: Array<number>) {
    await this.clientAdditionalWorkService.removeLinks(lineIds);
    this.priceOfferLinesGrid.refreshServerSideRows([], true, true);
    await this.getMeteringTotalPrices();
  }

  async transformToExtraLinkClientAdditionalWork(lineId: number) {
    await this.clientAdditionalWorkService.transformToExtraLinkClientAdditionalWork(this.projectId, this.additionalWorkId, lineId);
    this.priceOfferLinesGrid.refreshServerSideRows([], true, true);
    await this.getMeteringTotalPrices();
  }

  async rejectLinkClientAdditionalWork(lineId: number) {
    let ids = await this.clientAdditionalWorkService.rejectLinkClientAdditionalWork(this.projectId, this.additionalWorkId, lineId);
    let nodes = await this.priceOfferLinesGrid.refreshVisibleNodes(ids);
    this.priceOfferLinesGrid.gridOptions.api.redrawRows({ rowNodes: nodes });
    await this.getMeteringTotalPrices();
  }

  async validate() {
    let selectedLineIds = this.clientAdditionalWorkService.dataLines.filter(x => x.isSelected).map(x => x.id);
    this.gls.allow("", 0);

    let id = await this.clientAdditionalWorkService.create(this.projectId, {
      selectedLinesIds: selectedLineIds,
      description: this.entity?.description ?? "",
      validUntilDate: this.version?.validUntilDate ?? null,
      additionalDelayNbDays: this.version?.additionalDelayNbDays ?? null
    });
    this.clientAdditionalWorkService.dataLines = null;
    this.router.navigateToRoute('client-additional-work', { additionalWorkId: id });
  }

  canEditCell(line, colField) {
    if (line == null || colField == null) return false;
    if (this.isCreationMode) return false;
    if (line.additionalWorkTypeId == null && colField == "lineDescription" && line.priceOfferLineCategoryId != Constants.PriceOfferLineCategory.Data) return true;
    if (this.version.statusId != Constants.ClientAdditionalWorkStatusTypeId.Draft) return false;

    switch (colField) {
      case "quantityTypeId":
      case "unitId":
      case "proposedQuantity":
      case "unitQuantityFormulaDetailItemComposed":
      case "unitQuantityDetailItemComposed":
      case "marginCoefficient":
        return line.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data && (line.useInCompleteness || line.hasChildrenSubItem)
      case "dataLineActivities":
      case "dataLineDeliveries":
        return line.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data
          || line.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Chapter
      case "buyingUnitPrice":
        return line.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data
          && line.useInCompleteness;
      case "buyingClientValidationStatusId":
        return line.buyingClientValidationDeadlineDate != null;
      case "lineDescription":
      case "additionalDescriptionMultiline":
      case "buyingClientValidationStatusId":
      case "buyingClientValidationDeadlineDate":
      case "buyingClientValidationDate":
      case "deliveryDeadlineDate":
        return true;
      default:
        return false;
    }
  }
  public cellValueChanged(colField: string) {
    if (colField == null || colField.trim().length == 0) return;
    switch (colField) {
      case "lineDescription":
      case "additionalDescriptionMultiline":
      case "quantityTypeId":
      case "unitId":
      case "activityCodeId":
      case "tags":
      case "priceOfferLineFileCount":
        return;// Don't getMeteringTotalPrices for these properties
    }

    this.getMeteringTotalPrices();
  }

  private showSendOfferBox() {
    this.box.showCustomDialog(
      SendOffer,
      this.version.id,
      this.i18n.tr('clientadditionalwork.sendAdditionnalWork'),
      {
        canSave: false,
        size: 'lg',
        model: {
          generationParams: {
            projectId: this.projectId,
            companyIds: [],
            coverLetterContentHtml: this.version.coverLetterContentHtml,
            annexContentHtml: this.version.annexContentHtml,
            coverLetterContext: Constants.ContentTypeId.AdditionalWorkCoverLetter,
            annexContext: Constants.ContentTypeId.AdditionalWorkAnnex,
            emailContext: Constants.EmailContextId.Additionalwork,
            languageId: this.project.communicationLanguageId,
            signatoryIds: this.version.additionalWorkVersionSignatories.map(x => x.signatoryUserId),
            previewRequestParams: {
              projectId: this.projectId,
              thirdPartyId: this.project.mainThirdPartyId,
              priceOfferVersionId: this.entity.id
            },
            afterStep2: async (data: ReturnParams) => {
              await this.handleGenerationReturnParams(data);
            },
            hasSignatoryExternalStep3: true,
            hasSignatoryInternalStep3: true,
            items: [
              {
                id: 1,
                name: this.i18n.tr("priceofferversion.pdf"),
                items: [
                  {
                    id: 1,
                    icon: "digi-file-sync",
                    title: this.i18n.tr("priceofferversion.generate"),
                    description: this.i18n.tr("priceofferversion.pfdHasInternalModel"),
                    selected: true,
                    disabled: false,
                    handler: async (data: GenerationApiParams) => {
                      return await this.reportApiService.getAdditionalWorkPdf(this.selectedVersion, data.internSelectedId, data.architectSelectedId, data.selectedRefToDisplay);
                    }
                  }
                ]
              }
            ]
          }
        }
      }
    ).whenClosed(async (result) => {
      if (!result.wasCancelled) {
        await this.handleGenerationReturnParams(result.output);
        if (this.version.statusId == Constants.ClientAdditionalWorkStatusTypeId.Draft) {
          await this.setStatus(Constants.ClientAdditionalWorkStatusTypeId.Sent)
        }
      }
    });
  }
  async handleGenerationReturnParams(data: ReturnParams) {
    this.version.annexContentHtml = data.annexContentHtml;
    this.version.coverLetterContentHtml = data.coverLetterContentHtml;
    this.service.saveEntity(this.version, true);

    if (this.version.additionalWorkVersionSignatories.length > 0)
      await this.signatoryService.deleteEntities(this.version.additionalWorkVersionSignatories, false, null, true);

    data.signatoryIds.forEach(async (signatoryId) => {
      this.version.additionalWorkVersionSignatories.push(await this.signatoryService.createEntity({ signatoryUserId: signatoryId, additionalWorkVersionId: this.version.id }));
    });
    await this.signatoryService.saveEntities(this.version.additionalWorkVersionSignatories, true);
  }
  public async computeMeteringTotalPrice(id: string, dataLineApiService: IDataLineApiService, _this: any) {
    _this.footer = await dataLineApiService.getMeteringTotalPrices(parseInt(id));
  }
  private async previewAdditionnalWork() {
    this.gls.allow();
    var result = await this.reportApiService.getPreviewAdditionalWorkPdf(this.selectedVersion);
    this.fileHelper.previewFile(result);
  }
  public async getExcelFileName() {
    return (await this.projectService.getEntityById(this.projectId)).projectName + "_" + this.i18n.tr("projectindex.realization") + "_" + this.i18n.tr("realizationindex.clientAdditionalWorks") + "_" + this.entity.numAdditionalWork
  }
}
