import { AmendmentApiService } from "services/amendment-api-service";
import { AmendmentCreation } from './third-party-contract/amendment/amendment-creation';
import { Router } from 'aurelia-router';
import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { BuyingOfferApiService } from "services/buying-offer-api-service";
import * as Constants from '../../constants';
import { Box, IMenuGroup, GlobalLoaderService, ServiceBase } from "digiwall-lib";
import { PriceOfferLineMoveAction } from "../../constants";
import { DialogService } from "aurelia-dialog";
import { ICellRendererParams } from "ag-grid-community";
import { IDataLineApiService, MeteringTotalPrices } from "services/i-data-line-api-service";
import { BuyingCommonMetering } from "../metering/buying-common-metering";
import { AddItems } from "projects/quotes/add-items/add-items";
import * as toastr from 'toastr';
import { Merlin } from 'generated';
import { ProjectApiService } from 'services/project-api-service';
import { ColumnVisible } from 'module-list-tree-data/module-list-tree-data';
import { FullWidthCellRenderer } from "projects/quotes/version-offer-overlay-comp";
import { UpdateItemType } from "projects/quotes/update-items/update-items";
import { MeteringMenuItems } from "resources/metering/metering-menu-items";

@autoinject
export class BuyingOffer extends BuyingCommonMetering {
  ribbonHeaderText = this.i18n.tr('buyingindex.offer');

  public listIsFlat: boolean = false;
  public footer: MeteringTotalPrices;

  private nbDecimalForPriceDisplay: number;
  private nbDecimalForQuantityDisplay: number;

  public columnVisible: Array<ColumnVisible> =
    [
      { colId: "menuIems", visibility: true },
      { colId: "selected", visibility: true },
      { colId: "merlinRef", visibility: true },
      { colId: "merlinRefMetering", visibility: false },
      { colId: "merlinRefContext", visibility: true },
      { colId: "lineDescription", visibility: true },
      { colId: "lineRef", visibility: true },
      { colId: "originalMeasurementXlsLineNumber", visibility: true },
      { colId: "sheetIndex", visibility: true },
      { colId: "importFileName", visibility: true },
      { colId: "quantityTypeId", visibility: true },
      { colId: "unitId", visibility: true },
      { colId: "proposedQuantity", visibility: true },
      { colId: "proposedQuantityFormula", visibility: true },
      { colId: "priceOfferLineFileCount", visibility: true },
      { colId: "additionalDescriptionMultiline", visibility: true },
      { colId: "requestedQuantity", visibility: true },
      { colId: "acceptedRequestedQuantity", visibility: true },
      { colId: "dataLineDeliveries", visibility: true },
      { colId: "dataLineActivities", visibility: true },
      { colId: "buyingUnitPrice", visibility: true },
      { colId: "definedSellingUnitPrice", visibility: true },
      { colId: "definedDirectMarginPrice", visibility: true },
      { colId: "buyingUnitPrice", visibility: false },
      { colId: "sellingUnitPriceWithIndirectCostFromPAR", visibility: false },
      { colId: "marginCoefficient", visibility: true },
      { colId: "totalBuyingPrice", visibility: true },
      { colId: "totalSellingPrice", visibility: true },
      { colId: "tags", visibility: false },
      { colId: "features", visibility: true },
      { colId: "supplierId", visibility: true },
      { colId: "supplierPrice", visibility: false },
      { colId: "supplierQuantity", visibility: false },
      { colId: "supplierQuantityTypeId", visibility: false },
      { colId: "supplierTotal", visibility: false },
      { colId: "supplierComment", visibility: false },
      { colId: "acceptedPriceOfferBuyingUnitPrice", visibility: true },
      { colId: "buyingClientValidationStatusId", visibility: true },
      { colId: "buyingClientValidationDeadlineDate", visibility: true },
      { colId: "buyingClientValidationDate", visibility: true },
      { colId: "deliveryDeadlineDate", visibility: true },
      { colId: "unitQuantityFormulaDetailItemComposed", visibility: true },
      { colId: "unitQuantityDetailItemComposed", visibility: true },
      { colId: "contractNum", visibility: true },
      { colId: "clientAdditionalWorkNum", visibility: true },
      { colId: "clientAdditionalWorkStatusId", visibility: true },
    ];
  constructor(private buyingOfferApiService: BuyingOfferApiService, private i18n: I18N, box: Box, private globalLoaderService: GlobalLoaderService, dialogService: DialogService, router: Router, private projectApiService: ProjectApiService, private amendmentApiService: AmendmentApiService, private meteringMenuItems: MeteringMenuItems) {
    super(dialogService, box, buyingOfferApiService, router);
  }

  async activate(params) {
    this.projectId = params.projectId;
    this.buyingOfferApiService.projectId = this.projectId;
    this.getMeteringTotalPrices();
    let precisionParameter = await this.projectApiService.getPrecisionParameter(parseInt(params.projectId));
    this.nbDecimalForPriceDisplay = precisionParameter.nbDecimalForPriceDisplay;
    this.nbDecimalForQuantityDisplay = precisionParameter.nbDecimalForQuantityDisplay;
  }
  async deactivate() {
    await this.priceOfferLinesGrid?.deactivate()
  }

  public getGridMenuItems(params: ICellRendererParams): Array<IMenuGroup> {

    return [
      {
        group: "0",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("versionmetering.detailPost"),
            icon: "digi-add-circle-line",
            disabled: () => {
              //return this.cannotCreateLine(parseInt(params.node.id), Constants.PriceOfferLineCategory.Data, Constants.PriceOfferLineMoveAction.Into)
              let targetNode = this.priceOfferLinesGrid.gridOptions.api.getRowNode(params.node.id);
              return params.data?.additionalWorkTypeId != null || targetNode.data.priceOfferLineCategoryId != Constants.PriceOfferLineCategory.Data;
            },
            items: [
              {
                label: this.i18n.tr("versionmetering.detailPostAddItem"),
                disabled: () => {
                  return this.cannotCreateLine(parseInt(params.node.id), Constants.PriceOfferLineCategory.Data, Constants.PriceOfferLineMoveAction.Into) && params.data?.additionalWorkTypeId != null;
                },
                handler: async () => {
                  this.addItems(parseInt(params.node.id), Constants.PriceOfferLineMoveAction.Into);
                },
                icon: "digi-database-2-line"
              },
              {
                label: this.i18n.tr("versionmetering.detailPostAddPost"),
                handler: () => {
                  this.createLine(parseInt(params.node.id), Constants.PriceOfferLineCategory.Data, Constants.PriceOfferLineMoveAction.Into);
                },
                disabled: () => {
                  return this.cannotCreateLine(parseInt(params.node.id), Constants.PriceOfferLineCategory.Data, Constants.PriceOfferLineMoveAction.Into) && params.data?.additionalWorkTypeId != null;
                },
                icon: "digi-database-2-line"
              },
              {
                label: this.i18n.tr("versionmetering.detailPostAddComment"),
                handler: () => {
                  this.createLine(parseInt(params.node.id), Constants.PriceOfferLineCategory.Comment, Constants.PriceOfferLineMoveAction.Into);
                },
                disabled: () => {
                  return this.cannotCreateLine(parseInt(params.node.id), Constants.PriceOfferLineCategory.Comment, Constants.PriceOfferLineMoveAction.Into) && params.data.additionalWorkTypeId != null;
                },
                icon: "digi-list-check"
              },
            ]
          },
          {
            label: this.i18n.tr("versionmetering.addPost"),
            icon: "digi-database-2-line",
            items: this.getCreateLineMenuItem(params, parseInt(params.node.id), Constants.PriceOfferLineCategory.Data)
          },
          {
            id: Constants.CommonMeterginDataLineMenuItems.ADD_ITEM,
            label: this.i18n.tr("versionmetering.addItems"),
            icon: "digi-database-2-line",
            items: [
              {
                id: Constants.CommonMeterginDataLineMenuItems.ADD_ITEM_UP,
                label: this.i18n.tr("versionmetering.onTopOfLine"),
                disabled: () => {
                  return params.data?.additionalWorkTypeId != null || this.cannotCreateLine(parseInt(params.node.id), Constants.PriceOfferLineCategory.Data, Constants.PriceOfferLineMoveAction.Up);
                },
                handler: async () => {
                  this.addItems(parseInt(params.node.id), Constants.PriceOfferLineMoveAction.Up);
                },
                icon: "digi-arrow-up-line"
              },
              {
                id: Constants.CommonMeterginDataLineMenuItems.ADD_ITEM_DOWN,
                label: this.i18n.tr("versionmetering.belowLine"),
                disabled: () => {
                  return params.data?.additionalWorkTypeId != null || this.cannotCreateLine(parseInt(params.node.id), Constants.PriceOfferLineCategory.Data, Constants.PriceOfferLineMoveAction.Down);
                },
                handler: async () => {
                  this.addItems(parseInt(params.node.id), Constants.PriceOfferLineMoveAction.Down);
                },
                icon: "digi-arrow-down-line"
              },
              {
                id: Constants.CommonMeterginDataLineMenuItems.ADD_ITEM_CHILD,
                label: this.i18n.tr("versionmetering.childOfLine"),
                disabled: () => {
                  let targetNode = this.priceOfferLinesGrid.gridOptions.api.getRowNode(params.node.id);
                  return params.data?.additionalWorkTypeId != null || targetNode.data.priceOfferLineCategoryId != Constants.PriceOfferLineCategory.Chapter;
                },
                handler: async () => {
                  this.addItems(parseInt(params.node.id), Constants.PriceOfferLineMoveAction.Into);
                },
                icon: "digi-arrow-right-line"
              },
            ]
          },
          {
            label: this.i18n.tr("versionmetering.addChapter"),
            icon: "digi-list-check",
            items: this.getCreateLineMenuItem(params, parseInt(params.node.id), Constants.PriceOfferLineCategory.Chapter)
          },
          {
            label: this.i18n.tr("versionmetering.addComment"),
            icon: "digi-chat-4-line",
            items: this.getCreateLineMenuItem(params, parseInt(params.node.id), Constants.PriceOfferLineCategory.Comment)
          }
        ]
      },
      {
        group: "3",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("versionmetering.goToContract"),
            icon: "digi-duplicate",
            disabled: () => {
              return params.data?.priceOfferLineCategoryId != Constants.PriceOfferLineCategory.Data || (params.data?.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data && params.data?.contractId == null);
            },
            handler: async () => {
              this.router.navigate(`/projects/${this.projectId}/buying/contract/${params.data.contractId}`)
            },
          },
        ]
      },
      {
        group: "2",
        hiddenLabel: true,
        items: [
          this.meteringMenuItems.getUpdateItems(params, this.buyingOfferApiService, this.priceOfferLinesGrid, this.projectId.toString(), this.projectId.toString(), this.columnVisible, UpdateItemType.Other, this.router.currentInstruction.config.moduleId, false, this.computeMeteringTotalPrice, this),
          {
            id: Constants.CommonMeterginDataLineMenuItems.ADD_DOCUMENTS,
            label: this.i18n.tr("versionmetering.adddocument"),
            icon: "digi-folder-add-line",
            disabled: () => params.data?.additionalWorkTypeId != null,
            handler: () => {
              this.addPriceOfferLineDocument(params.node.data.id)
            }
          }
        ]
      },
      {
        group: "4",
        hiddenLabel: true,
        items: [
          {
            id: Constants.CommonMeterginDataLineMenuItems.DELETE,
            label: this.i18n.tr("versionmetering.deleteLine"),
            icon: "digi-trash",
            disabled: () => params.data?.additionalWorkTypeId != null || params.data?.originalMeasurementXlsLineNumber != null,
            handler: async () => {
              await this.deleteLine(params.node.data.id)
            }
          }
        ]
      },
      AmendmentCreation.getCreateAmendmentMenuItem(this.projectId, params.node.data.id, null, !params.data?.canCreateAmendment, () => { this.priceOfferLinesGrid.listTreeData.refreshServerSideRows(null, true, true); })
    ]
  }

  canEditCell(line, colField) {
    if (line == null || colField == null) return false;
    if (line.additionalWorkTypeId != null) return false;
    if (line.originalPriceOfferLineId != null) {
      switch (colField) {
        case "buyingUnitPrice":
          return line.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data
            && line.useInCompleteness;
        case "buyingClientValidationStatusId":
          return line.buyingClientValidationDeadlineDate != null;
        case "lineDescription":
        case "additionalDescriptionMultiline":
        case "buyingClientValidationDeadlineDate":
        case "buyingClientValidationDate":
        case "deliveryDeadlineDate":
          return true;
        default:
          return false;
      }
    }
    switch (colField) {
      case "quantityTypeId":
      case "unitId":
      case "proposedQuantity":
      case "unitQuantityFormulaDetailItemComposed":
      case "unitQuantityDetailItemComposed":
      case "marginCoefficient":
        return line.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data && (line.useInCompleteness || line.hasChildrenSubItem)
      case "dataLineActivities":
      case "dataLineDeliveries":
        return line.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data
          || line.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Chapter
      case "buyingUnitPrice":
        return line.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data
          && line.useInCompleteness;
      case "buyingClientValidationStatusId":
        return line.buyingClientValidationDeadlineDate != null;
      case "lineDescription":
      case "additionalDescriptionMultiline":
      case "buyingClientValidationStatusId":
      case "buyingClientValidationDeadlineDate":
      case "buyingClientValidationDate":
      case "deliveryDeadlineDate":
        return true;
      default:
        return false;
    }
  }

  getBottomRowRenderer() {
    return FullWidthCellRenderer;
  }

  public async getMeteringTotalPrices() {
    this.footer = await this.buyingOfferApiService.getMeteringTotalPrices(parseInt(this.projectId.toString()));
  }

  private getCreateLineMenuItem(params, targetId: number, category: Constants.PriceOfferLineCategory) {
    return [
      {
        label: this.i18n.tr("versionmetering.onTopOfLine"),
        handler: () => {
          this.createLine(targetId, category, Constants.PriceOfferLineMoveAction.Up);
        },
        disabled: () => {
          return params.data?.additionalWorkTypeId != null || this.cannotCreateLine(targetId, category, Constants.PriceOfferLineMoveAction.Up);
        },
        icon: "digi-arrow-up-line"
      },
      {
        label: this.i18n.tr("versionmetering.belowLine"),
        handler: () => {
          this.createLine(targetId, category, Constants.PriceOfferLineMoveAction.Down);
        },
        disabled: () => {
          return params.data?.additionalWorkTypeId != null || this.cannotCreateLine(targetId, category, Constants.PriceOfferLineMoveAction.Down);
        },
        icon: "digi-arrow-down-line"
      },
      {
        label: this.i18n.tr("versionmetering.childOfLine"),
        handler: () => {
          this.createLine(targetId, category, Constants.PriceOfferLineMoveAction.Into);
        },
        disabled: () => {
          let targetNode = this.priceOfferLinesGrid.gridOptions.api.getRowNode(targetId.toString());
          return params.data?.additionalWorkTypeId != null || targetNode.data.priceOfferLineCategoryId != Constants.PriceOfferLineCategory.Chapter;
        },
        icon: "digi-arrow-right-line"
      },
    ]
  }
  private cannotCreateLine(targetId, categoryId, moveType) {
    let targetNode = this.priceOfferLinesGrid.gridOptions.api.getRowNode(targetId.toString());
    if (targetNode.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data) {
      if (moveType == Constants.PriceOfferLineMoveAction.Into &&

        (((!targetNode.data.isDetailedLine || !targetNode.data.isSubItem) && categoryId == Constants.PriceOfferLineCategory.Chapter) ||

          (targetNode.data.hasChildrenDetailed && categoryId != Constants.PriceOfferLineCategory.Comment) ||

          (targetNode.data.originalMeasurementXlsLineNumber == null && categoryId != Constants.PriceOfferLineCategory.Comment))) {

        return true;
      }
      if (targetNode.data.isDetailedLine && categoryId != Constants.PriceOfferLineCategory.Comment) {
        return true;
      }
      if (targetNode.data.isSubItem && (categoryId == Constants.PriceOfferLineCategory.Chapter || (moveType == Constants.PriceOfferLineMoveAction.Into && categoryId != Constants.PriceOfferLineCategory.Comment))) {
        return true;
      }
    }
    if (targetNode.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Comment && categoryId == Constants.PriceOfferLineCategory.Chapter && targetNode.parent.data?.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data) {
      return true;
    }
    return false;
  }
  private async createLine(targetId: number, categoryId: number, action: Constants.PriceOfferLineMoveAction) {
    let targetNode = this.priceOfferLinesGrid.gridOptions.api.getRowNode(targetId.toString());
    if (!targetNode) {
      console.warn('Target node not found!');
      return;
    }

    targetNode.data.isSelected = false;

    this.priceOfferLinesGrid.gridOptions.api.showLoadingOverlay();
    this.globalLoaderService.allow();
    let ids = await this.buyingOfferApiService.create(this.projectId, targetId, categoryId, action);
    this.priceOfferLinesGrid.gridOptions.api.hideOverlay();

    this.priceOfferLinesGrid.refreshServerSideRows(ids, targetNode.data.parentId == null && action != PriceOfferLineMoveAction.Into);
    if (action == PriceOfferLineMoveAction.Into) {
      targetNode.setExpanded(true);
    }

  }

  private async duplicateLine(targetRowId: string) {
    let targetNode = this.priceOfferLinesGrid.gridOptions.api.getRowNode(targetRowId.toString());
    if (!targetNode) {
      console.warn('Target node not found!');
      return;
    }

    targetNode.data.isSelected = false;

    this.globalLoaderService.allow();
    this.priceOfferLinesGrid.gridOptions.api.showLoadingOverlay();
    let ids = await this.buyingOfferApiService.duplicate(this.projectId, parseInt(targetNode.data.id));
    this.priceOfferLinesGrid.gridOptions.api.hideOverlay();

    this.priceOfferLinesGrid.refreshServerSideRows(ids, targetNode.data.parentId == null);
  }

  private cantMoveToThisNodeChildren(targetRowId: string) {
    let targetNode = this.priceOfferLinesGrid.gridOptions.api.getRowNode(targetRowId.toString());
    if (!targetNode) {
      console.warn('Target node not found!');
      return true;
    }

    if (targetNode.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data) {
      return true;
    }
    if (targetNode.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Comment && targetNode.parent.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data) {
      return true;
    }
    if (targetNode.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Comment && targetNode.parent.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Chapter) {
      return true;
    }

    return false;
  }

  private async deleteLine(id: number) {
    this.globalLoaderService.allow();
    this.priceOfferLinesGrid.gridOptions.api.showLoadingOverlay();

    let idsToDelete = this.priceOfferLinesGrid.gridOptions.api.getSelectedNodes().map(x => x.data.id);
    if (idsToDelete.length == 0 || idsToDelete.findIndex(x => x == id) == -1)
      idsToDelete.push(id);

    let ids = await this.buyingOfferApiService.delete(this.projectId, idsToDelete);
    this.priceOfferLinesGrid.gridOptions.api.hideOverlay();
    idsToDelete.forEach(id => {
      let node = this.priceOfferLinesGrid.gridOptions.api.getRowNode(id.toString());
      this.priceOfferLinesGrid.refreshServerSideRows(ids, node?.data?.parentId == null);
    });
  }

  public async addItems(targetId: number, action: Constants.PriceOfferLineMoveAction) {
    let targetNode = this.priceOfferLinesGrid.gridOptions.api.getRowNode(targetId.toString());
    if (!targetNode) {
      console.warn('Target node not found!');
      return;
    }

    await this.box.showCustomDialog(AddItems, targetId, null, {
      model: {
        action: action,
        projectId: this.projectId,
        api: this.buyingOfferApiService
      }, size: "xl"
    }).whenClosed((result) => {
      if (result.output) {
        this.priceOfferLinesGrid.refreshServerSideRows(result.output, targetNode.data.parentId == null && action != PriceOfferLineMoveAction.Into);
        if (action == PriceOfferLineMoveAction.Into) {
          targetNode.setExpanded(true);
        }
      }
    });
  }

  public async addItemLink(targetId: number) {
    let itemId;

    await this.box.showCustomDialog(AddItems, targetId, null, {
      model: {
        projectId: this.projectId,
        mode: Constants.ItemLinkMode.SingleLink,
        api: this.buyingOfferApiService
      }, size: "xl"
    }).whenClosed((result) => {
      if (result.output) {
        itemId = result.output;
      }
    });

    let targetNode = this.priceOfferLinesGrid.gridOptions.api.getRowNode(targetId.toString());
    // Call api
    let result = await this.buyingOfferApiService.patch(this.projectId, parseInt(targetNode.data.id), "itemId", itemId);
    if (result != null && result.length) {
      await this.priceOfferLinesGrid.refreshVisibleNodes(result)
    } else {
      toastr.error("Error during saving data");
    }
  }

  public async removeItemLink(targetId: number) {
    let targetNode = this.priceOfferLinesGrid.gridOptions.api.getRowNode(targetId.toString());
    // Call api
    let result = await this.buyingOfferApiService.patch(this.projectId, parseInt(targetNode.data.id), "itemId", null);
    if (result != null && result.length) {
      this.priceOfferLinesGrid.refreshVisibleNodes(result);

    } else {
      toastr.error("Error during saving data");
    }
  }

  public async computeMeteringTotalPrice(id: string, dataLineApiService: IDataLineApiService, _this: any) {
    _this.footer = await dataLineApiService.getMeteringTotalPrices(parseInt(id));
  }
  public async getExcelFileName() {
    return (await this.projectService.getEntityById(this.projectId)).projectName + "_" + this.i18n.tr("projectindex.buying") + "_" + this.i18n.tr("realizationindex.metering")
  }
}
