import { autoinject } from "aurelia-dependency-injection";
import { DialogService } from "aurelia-dialog";
import { I18N } from "aurelia-i18n";
import { IMenuGroup, ServiceBase, Box, GlobalLoaderService, EnumerationType, IMenuItems, DialogBoxViewModel, ActionDialogBoxInputParameters, KeyboardShortcut } from "digiwall-lib";
import { ColumnVisible } from "module-list-tree-data/module-list-tree-data";
import { BuyingCommonMetering } from "projects/metering/buying-common-metering";
import { MeteringTotalPrices, ProgressStatementTotal } from "services/i-data-line-api-service";
import { ClientProgressStatementApiService, ClientProgressStatementLineApiService } from "services/progress-statement-api-service";
import { Router } from 'aurelia-router';
import { ICellRendererParams } from "ag-grid-community";
import { ProjectApiService } from "services/project-api-service";
import * as Constants from '../../../constants';
import { Merlin } from "generated";
import { Predicate, FilterQueryOp } from "breeze-client";
import moment from "moment";
import { SynchroSelect } from "./synchro-select";

@autoinject
export class ProgressStatement extends BuyingCommonMetering {
  public listIsFlat: boolean = false;
  ribbonHeaderText = this.i18n.tr('realizationindex.progressStatementInProgress');
  public footer: ProgressStatementTotal;
  public buyingAdditionalWorkTypeList: Array<EnumerationType>;
  public progressStatementService: ServiceBase<Merlin.Web.Model.ProgressStatement>;
  menuItems: IMenuItems[];
  private agGridViewModuleView = "progress-statement";
  private nbDecimalForPriceDisplay: number
  private nbDecimalForQuantityDisplay: number
  private canCreateProgressStatement: boolean = false;
  public columnVisible: Array<ColumnVisible> =
    [
      { colId: "menuIems", visibility: true },
      { colId: "selected", visibility: true },
      { colId: "merlinRef", visibility: true },
      { colId: "merlinRefMetering", visibility: false },
      { colId: "merlinRefContext", visibility: true },
      { colId: "lineDescription", visibility: true },
      { colId: "lineRef", visibility: true },
      { colId: "originalMeasurementXlsLineNumber", visibility: true },
      { colId: "sheetIndex", visibility: true },
      { colId: "importFileName", visibility: true },
      { colId: "quantityTypeId", visibility: true },
      { colId: "unitId", visibility: true },
      { colId: "proposedQuantity", visibility: true },
      { colId: "proposedQuantityFormula", visibility: true },
      { colId: "priceOfferLineFileCount", visibility: true },
      { colId: "additionalDescriptionMultiline", visibility: true },
      { colId: "requestedQuantity", visibility: true },
      { colId: "acceptedRequestedQuantity", visibility: true },
      { colId: "dataLineDeliveries", visibility: true },
      { colId: "dataLineActivities", visibility: true },
      { colId: "buyingUnitPrice", visibility: true },
      { colId: "definedSellingUnitPrice", visibility: true },
      { colId: "definedDirectMarginPrice", visibility: true },
      { colId: "buyingUnitPrice", visibility: false },
      { colId: "sellingUnitPriceWithIndirectCostFromPAR", visibility: false },
      { colId: "marginCoefficient", visibility: true },
      { colId: "totalBuyingPrice", visibility: true },
      { colId: "totalSellingPrice", visibility: true },
      { colId: "tags", visibility: false },
      { colId: "supplierId", visibility: true },
      { colId: "supplierPrice", visibility: false },
      { colId: "supplierQuantity", visibility: false },
      { colId: "supplierQuantityTypeId", visibility: false },
      { colId: "supplierTotal", visibility: false },
      { colId: "supplierComment", visibility: false },
      { colId: "acceptedPriceOfferBuyingUnitPrice", visibility: true },
      { colId: "buyingClientValidationStatusId", visibility: false },
      { colId: "buyingClientValidationDeadlineDate", visibility: false },
      { colId: "buyingClientValidationDate", visibility: false },
      { colId: "deliveryDeadlineDate", visibility: false },
      { colId: "unitQuantityDetailItemComposed", visibility: true },
      { colId: "unitQuantityFormulaDetailItemComposed", visibility: true },
      { colId: "quantityTypeSupplierTypeId", visibility: true },
      { colId: "totalQuantityDone", visibility: true },
      { colId: "totalQuantityPercentageDone", visibility: true },
      { colId: "isFinalised", visibility: true },
      { colId: "deltaQuantityDone", visibility: true },
      { colId: "deltaQuantityDonePercentage", visibility: true },
      { colId: "absoluteQuantityDone", visibility: true },
      { colId: "absoluteQuantityDonePercentage", visibility: true }
    ];

  constructor(public progressStatementLineApiService: ClientProgressStatementLineApiService, public progressStatementApiService: ClientProgressStatementApiService, private i18n: I18N, box: Box, private globalLoaderService: GlobalLoaderService, dialogService: DialogService, router: Router, private projectApiService: ProjectApiService) {
    super(dialogService, box, progressStatementLineApiService, router);
    this.progressStatementService = new ServiceBase<Merlin.Web.Model.ProgressStatement>(Constants.EntityTypeNames.ProgressStatement)
  }

  async activate(params) {
    this.projectId = params.projectId;
    this.progressStatementLineApiService.projectId = this.projectId;
    let precisionParameter = await this.projectApiService.getPrecisionParameter(parseInt(params.projectId));
    this.nbDecimalForPriceDisplay = precisionParameter.nbDecimalForPriceDisplay;
    this.nbDecimalForQuantityDisplay = precisionParameter.nbDecimalForQuantityDisplay;
    this.canCreateProgressStatement = await this.checkCanCreateProgressStatement();
    this.setHeaderMenuItems();
    let p1 = new Predicate("projectId", FilterQueryOp.Equals, this.projectId);
    let p2 = new Predicate("acceptationDate", FilterQueryOp.Equals, null);
    let p3 = new Predicate("subContractorId", FilterQueryOp.Equals, null);
    let previousProgressStatement: Merlin.Web.Model.ProgressStatement = (await this.progressStatementService.getEntities(p1.and(p2.and(p3)), ['progressStatementVersions']))?.sortBy('id')?.last();
    if (previousProgressStatement != null) {
      this.ribbonHeaderText += " V." + previousProgressStatement.progressStatementVersions.sortBy('versionId').last().versionId.toString() + " - " + (previousProgressStatement.periodStartDate != null ? moment(previousProgressStatement.periodStartDate).format('DD/MM/YYYY') : " / ") + " - " + moment(previousProgressStatement.periodEndDate).format('DD/MM/YYYY');
    }

    await this.getMeteringTotalPrices();
  }

  async deactivate() {
    await this.priceOfferLinesGrid?.deactivate()
  }

  private setHeaderMenuItems() {
    this.menuItems = [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("progressstatement.save"),
            icon: "digi-save",
            //disabled: !this.canCreateProgressStatement,
            handler: async () => {
              await this.createProgressStatement();
            }
          },
          {
            label: this.i18n.tr("progressstatement.synchroWithSubcontractor"),
            icon: "digi-synchronization",
            //disabled: !this.canCreateProgressStatement,
            handler: async () => {
              await this.synchroWithSubcontractor();
            }
          }
        ]
      },
    ]
  }
  async synchroWithSubcontractor() {
    let view = new SynchroSelect(this.projectId)
    await this.box.showFloatingBox(view, false, false)
      .whenClosed(async (result) => {
        if (!result.wasCancelled && result.output) {
          let ids = await this.progressStatementLineApiService.synchroWithSubContractor(this.projectId, result.output);
          this.priceOfferLinesGrid.refreshVisibleNodes(ids);
        }
      })
  }

  public getGridMenuItems(params: ICellRendererParams): Array<IMenuGroup> {
    return []
  }
  async getMeteringTotalPrices() {
    this.footer = await this.progressStatementLineApiService.getTotals();
  }
  canEditCell(line: any, colField: any) {
    switch (colField) {
      case "deltaQuantityDone":
      case "absoluteQuantityDone":
        return line.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data
          && line.useInCompleteness;

      case "absoluteQuantityDonePercentage":
      case "deltaQuantityDonePercentage":
        return line.priceOfferLineCategoryId != Constants.PriceOfferLineCategory.Comment
      default:
        return false;
    }
  }

  private async checkCanCreateProgressStatement() {
    return await this.progressStatementLineApiService.CheckCanCreateProgressStatement(this.projectId);
  }

  private async createProgressStatement() {
    let msg = this.i18n.tr('progressstatement.confirmationQuestion');

    let buttonYes: ActionDialogBoxInputParameters =
    {
      label: this.i18n.tr("general.ok", { ns: "common" }),
      title: this.i18n.tr("general.ok", { ns: "common" }),
      theme: 'primary',
      type: 'solid',
      disabled: false,
      fn: (thisBox: DialogBoxViewModel) => {
        thisBox.controller.ok(true);
      },
      keyboardShortcut: KeyboardShortcut.Enter
    };
    let buttonNo: ActionDialogBoxInputParameters =
    {
      label: this.i18n.tr("general.cancel", { ns: "common" }),
      title: this.i18n.tr("general.cancel", { ns: "common" }),
      theme: 'dark',
      type: 'ghost',
      disabled: false,
      fn: (thisBox: DialogBoxViewModel) => {
        thisBox.controller.ok(false);
      },
      keyboardShortcut: KeyboardShortcut.Escape
    };
    await this.box.showQuestion(msg, this.i18n.tr("menu.question", { ns: "common" }), [buttonNo, buttonYes])
      .whenClosed(async (result) => {
        if (!result.wasCancelled && result.output) {
          let progressStatementId = await this.progressStatementLineApiService.getLastProgressStatementId(this.projectId);
          this.router.navigateToRoute('progress-statement-detail', { projectId: this.projectId, progressStatementId: progressStatementId, progressStatementVersionId: -100 });
        }
      })
  }

  public async getExcelFileName() {
    return (await this.projectService.getEntityById(this.projectId)).projectName + "_" + this.i18n.tr("projectindex.realization") + "_" + this.ribbonHeaderText;
  }

}

