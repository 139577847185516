import { Predicate, FilterQueryOp } from 'breeze-client';
import { HttpClient } from 'aurelia-fetch-client';
import { DialogController } from 'aurelia-dialog';
import { CustomLogger, ServiceBase, EnumerationTypeService, UITreeNode, ListViewModelBase, FieldType, ActionDialogBoxInputParameters, DialogBoxViewModel, UIInternal, GlobalLoaderService, EnumerationType } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject, observable, BindingEngine } from 'aurelia-framework';
import * as Constants from '../../../constants';
import { DataFormat } from 'select2';
import { Merlin } from 'generated';
import { GridHelperMerlin } from 'resources/elements/grid-helper';
import { ColDef } from 'ag-grid-community';
import { ItemPrice } from 'utils/item-price';
import { IDataLineApiService } from 'services/i-data-line-api-service';
import { ItemApiService } from 'services/item-api-service';
import { param } from 'jquery';

@autoinject
export class AddItems extends ListViewModelBase<Merlin.Web.Model.Item> {
  projectId: any;

  get ribbonHeaderText() {
    return ""
  }

  private agGrid: any;

  @observable
  private selectedActivites: DataFormat = { id: "", text: null };

  private articleFamiliesTree: UITreeNode[] = [];
  private selectedTreeValues: string[] = [];
  private articleFamilyService: ServiceBase<Merlin.Web.Model.ItemFamily>;

  // private unitService: ServiceBase<Merlin.Web.Model.Unit>;

  public itemActivityCodeService: EnumerationTypeService;
  private selectedItems: Array<PriceOfferLineFromItem> = []

  private targetId: number
  private workQuotationVersionId: number;
  private priceOfferVersionId: number;
  private supplierOfferId: number;
  private clientAdditionalWorkVersionId: number;
  private itemCompostionId: number;
  private action: Constants.PriceOfferLineMoveAction
  private mode: Constants.ItemLinkMode = Constants.ItemLinkMode.MultipleCreation;
  private parent: any;
  private title: string;

  private api: IDataLineApiService;
  private selectedItemsUITable: HTMLElement;
  private viewModule: string;


  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine, private dialogController: DialogController, private httpClient: HttpClient, private itemApiService: ItemApiService, private globalLoaderService: GlobalLoaderService) {
    super(router, logger, new ServiceBase<Merlin.Web.Model.Item>(Constants.EntityTypeNames.Item));
    this.articleFamilyService = new ServiceBase<Merlin.Web.Model.ItemFamily>(Constants.EntityTypeNames.ItemFamily, null, 'ItemFamiliesByActivityCode');
    this.itemActivityCodeService = new EnumerationTypeService(Constants.EnumerationTypes.ActivityCode);
    // this.unitService = new ServiceBase<Merlin.Web.Model.Unit>(Constants.EntityTypeNames.Unit);
    this.service.gridDataSource.expands = ['itemType', 'itemDeliveryTypes.itemDeliveryEnum', 'unitType', 'defaultUnit', 'itemPrices.itemPriceFromDates', 'itemCompositions', 'features.itemFeatureEnum', 'activityCodes.activityCodeEnum'];
    this.service.gridDataSource.queryParameters = { isDeprecated: false, isValidated: true };
  }

  public ressourceName: string = Constants.EntityTypeNames.RequestSupplierOffer;

  public async activate(params: any) {
    if (params.action != null)
      this.action = params.action;

    if (params.workQuotationVersionId != null)
      this.workQuotationVersionId = params.workQuotationVersionId;

    if (params.priceOfferVersionId != null)
      // this.priceOfferVersionId = params.priceOfferVersionId;

      if (params.supplierOfferId != null) {
        this.supplierOfferId = params.supplierOfferId;
      }
    if (params.clientAdditionalWorkVersionId != null) {
      this.clientAdditionalWorkVersionId = params.clientAdditionalWorkVersionId;
    }

    if (params.itemCompostionId != null) {
      this.viewModule = "add-items-in-composition"
      this.itemCompostionId = params.itemCompostionId;
    }
    else {
      this.viewModule = "add-items-in-metering"
    }

    if (params.param1 != null)
      this.targetId = params.param1;

    if (params.mode != null)
      this.mode = params.mode

    if (params.parent != null)
      this.parent = params.parent

    this.api = params.api;
    this.projectId = params.projectId;

    this.title = this.i18n.tr("versionmetering.addItems") + (this.parent ? ' : ' + this.parent.lineDescription + ' ' + this.parent.unitTranslatedString + ' ' + this.parent.proposedQuantity : '');

    this.refreshTreeValues();
  }

  public async attached() {
    await super.attached();
    this.disposables.push(
      this.bindingEngine.collectionObserver(this.selectedTreeValues).subscribe(changeRecords => {
        this.filterItems();
      })
    );

    this.agGrid.calculateBodyHeight(0);
  }

  async close() {
    if (this.selectedItems.length > 0) {
      let buttonYes: ActionDialogBoxInputParameters =
      {
        label: this.i18n.tr("general.yes", { ns: "common" }),
        title: this.i18n.tr("general.yes", { ns: "common" }),
        theme: 'primary',
        type: 'solid',
        disabled: false,
        fn: (thisBox: DialogBoxViewModel) => {
          thisBox.controller.ok();
        }
      };
      let buttonNo: ActionDialogBoxInputParameters =
      {
        label: this.i18n.tr("general.no", { ns: "common" }),
        title: this.i18n.tr("general.no", { ns: "common" }),
        theme: 'dark',
        type: 'ghost',
        disabled: false,
        fn: (thisBox: DialogBoxViewModel) => {
          thisBox.controller.cancel();
        }
      };
      await this.box.showQuestion(this.i18n.tr('priceofferline.cancelAddItem'), this.i18n.tr('menu.question'), [buttonNo, buttonYes]).whenClosed(
        async (result) => {
          if (!result.wasCancelled) {
            await this.dialogController.close(true);
          }
        }
      )
    }
    else {
      await this.dialogController.close(true);
    }

  }

  async ok() {
    this.selectedItems = this.selectedItems.map((item) => {
      item.item = null;
      item.unit = null;
      item.unitService = null;
      item.activity = null;
      item.activityService = null;
      item.deliveryService = null;
      item.delivranceType = null;
      return item
    });

    this.globalLoaderService.allow();
    let result = await this.api.createFromItems(this.getVersionId(), this.selectedItems);
    if (result) {
      this.dialogController.close(true, result);
    }

    return [];
  }

  private getVersionId() {
    let versionId = this.workQuotationVersionId;
    if (this.priceOfferVersionId != null) {
      versionId = this.priceOfferVersionId;
    }
    if (this.itemCompostionId != null) {
      versionId = this.itemCompostionId;
    }
    if (this.supplierOfferId != null) {
      versionId = this.supplierOfferId;
    }
    if (this.clientAdditionalWorkVersionId != null) {
      versionId = this.clientAdditionalWorkVersionId;
    }
    return versionId;
  }

  onCellClicked(entity: Merlin.Web.Model.Item): boolean | null {
    if (this.mode == Constants.ItemLinkMode.MultipleCreation) {
      this.createLineFromItem(entity).then((newLine) => {
        this.initLineFromItemObserver(newLine);
        this.selectedItems.push(newLine);
      });
    }

    if (this.mode == Constants.ItemLinkMode.SingleLink) {
      let buttonYes: ActionDialogBoxInputParameters =
      {
        label: this.i18n.tr("general.yes", { ns: "common" }),
        title: this.i18n.tr("general.yes", { ns: "common" }),
        theme: 'primary',
        type: 'solid',
        disabled: false,
        fn: (thisBox: DialogBoxViewModel) => {
          thisBox.controller.ok();
        }
      };
      let buttonNo: ActionDialogBoxInputParameters =
      {
        label: this.i18n.tr("general.no", { ns: "common" }),
        title: this.i18n.tr("general.no", { ns: "common" }),
        theme: 'dark',
        type: 'ghost',
        disabled: false,
        fn: (thisBox: DialogBoxViewModel) => {
          thisBox.controller.cancel();
        }
      };

      this.box.showQuestion(this.i18n.tr('versionmetering.linkItem'), this.i18n.tr('menu.question'), [buttonNo, buttonYes]).whenClosed(
        async (result) => {
          if (!result.wasCancelled) {
            await this.dialogController.close(true, entity.id);
          }
        }
      )
    } else {
      setTimeout(() => {
        this.selectedItemsUITable?.scrollTo({ top: this.selectedItemsUITable.scrollHeight });
      }, 300);
    }

    return true;
  }

  public getMenuItems(params) {
    return [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.remove"),
            icon: "digi-trash",
            handler: () => {
              this.removeItem(params);
            }
          }
        ]
      },
      {
        group: "2",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.removeAll"),
            icon: "digi-trash",
            handler: () => {
              this.removeAllItems();
            }
          }
        ]
      }
    ];
  }

  removeAllItems() {
    this.selectedItems.splice(0);
  }
  removeItem(record: PriceOfferLineFromItem) {
    this.selectedItems.splice(this.selectedItems.indexOf(record), 1);
  }

  private initLineFromItemObserver(line: PriceOfferLineFromItem) {
    if (line != null) {
      this.disposables.push(
        this.bindingEngine.propertyObserver(line, "quantity").subscribe(async (newVal, oldVal) => {
          if (newVal != oldVal) {
            await this.recomputeLinePrice(line);
          }
        }),
        this.bindingEngine.propertyObserver(line, "unitId").subscribe(async (newVal, oldVal) => {
          if (newVal != oldVal) {
            await this.recomputeLinePrice(line);
          }
        })
      )
    }
  }

  private async recomputeLinePrice(line: PriceOfferLineFromItem) {
    line.price = await this.getPrice(line);
    line.total = line.quantity * line.price;
  }

  async createLineFromItem(item: Merlin.Web.Model.Item): Promise<PriceOfferLineFromItem> {
    let unitService = new ServiceBase<Merlin.Web.Model.Unit>(Constants.EntityTypeNames.Unit);
    unitService.gridDataSource.queryParameters = { itemId: item.id }
    let activityService = new EnumerationTypeService(Constants.EnumerationTypes.ActivityCode);
    activityService.gridDataSource.queryParameters = { itemId: item.id, category: Constants.EnumerationTypes.ActivityCode }
    let deliveryService = new EnumerationTypeService(Constants.EnumerationTypes.ItemDeliveryType);
    deliveryService.gridDataSource.queryParameters = { itemId: item.id, category: Constants.EnumerationTypes.ItemDeliveryType }

    let defaultBuyingPrice;
    let defaultMarginCoefficient;

    if (item.itemTypeId != Constants.ItemType.Composed) {
      let resultPrice = this.getItemPriceCurrent(item.itemPrices?.filter(x => x.isDefaultPrice)[0])
      if (resultPrice != null) {
        defaultBuyingPrice = resultPrice.unitBuyingPrice;
        defaultMarginCoefficient = resultPrice.defaultMarginCoefficientPrice;
      }
    }
    else {
      let defaultPrice = item.itemCompositions?.filter(x => x.isDefaultPrice);
      if (defaultPrice.length > 0) {
        defaultBuyingPrice = defaultPrice[0].totalBuyingPrice;
        defaultMarginCoefficient = defaultPrice[0].marginCoefficient;
      }
    }

    let newItem: PriceOfferLineFromItem = {
      itemId: item.id,
      item: item,
      description: item.description._translation != null ? item.description._translation : item.articleCode,
      price: defaultBuyingPrice,
      unit: item.defaultUnit,
      unitId: item.defaultUnitId,
      quantity: 1,
      action: this.action,
      targetId: this.targetId,
      workQuotationVersionId: this.workQuotationVersionId,
      priceOfferVersionId: this.priceOfferVersionId,
      projectId: this.projectId,
      itemCompositionId: this.itemCompostionId,
      marginCoef: defaultMarginCoefficient,
      total: defaultBuyingPrice,
      unitService: unitService,
      nbUnit: await unitService.getCount(null, { itemId: item.id }),
      activity: item.activityCodes.length == 1 ? item.activityCodes[0].activityCodeEnum : null,
      activityId: item.activityCodes.length == 1 ? item.activityCodes[0].activityCodeEnumId : null,
      nbActivity: item.activityCodes.length,
      activityService: activityService,
      delivranceType: item.itemDeliveryTypes.length == 1 && item.itemTypeId != Constants.ItemType.Composed ? item.itemDeliveryTypes[0].itemDeliveryEnum : null,
      delivranceTypeId: item.itemDeliveryTypes.length == 1 && item.itemTypeId != Constants.ItemType.Composed ? item.itemDeliveryTypes[0].itemDeliveryEnumId : null,
      nbDelivery: item.itemDeliveryTypes.length,
      deliveryService: deliveryService,
      IsComposition: item.itemTypeId == Constants.ItemType.Composed
    };
    await this.recomputeLinePrice(newItem)
    return newItem;
  }

  public getItemPriceCurrent(itemPrice) {
    if (itemPrice != null) {
      return ItemPrice.getCurrentPriceFromDate(itemPrice, true);
    }
  }

  async getPrice(record: PriceOfferLineFromItem) {
    if (record.item != null && record.quantity != null) {
      if (record.item.itemTypeId == Constants.ItemType.Composed) {
        let compo = record.item.itemCompositions.sortBy("fromQuantity", false).find(x => x.fromQuantity <= record.quantity);
        if (compo == null) {
          return record.item.defaultBuyingPrice;
        }
        let prices = await this.itemApiService.computePrices(compo);
        return prices.totalBuyingPrice;
      }
      else {
        let itemPrice = ItemPrice.getPrice(record.item, record.quantity, record.unitId) as Merlin.Web.Model.ItemPrice;
        if (itemPrice != null) {
          let itemPriceFromDate = ItemPrice.getCurrentPriceFromDate(itemPrice, true);
          record.marginCoef = itemPriceFromDate.defaultMarginCoefficientPrice;
          return itemPriceFromDate.unitBuyingPrice
        }
        return 0
      }

    }
  }

  // async getTotal(item: Merlin.Web.Model.Item, proposedQuantity: number, record: PriceOfferLineFromItem) {
  //   return proposedQuantity * (await this.getPrice(item, proposedQuantity, record));
  // }

  async selectedActivitesChanged(newValue, oldValue) {
    if (newValue != null && newValue != oldValue) {
      await this.refreshTreeValues();
      this.filterItems();

    }
  }

  private filterItems() {
    this.service.gridDataSource.queryParameters = { activityCodeId: this.selectedActivites?.id, familiesId: this.selectedTreeValues, isDeprecated: false, isValidated: true };
    if (this.gridOptions != null && this.gridOptions.api != null) {
      this.gridOptions?.api?.onFilterChanged();
    }
  }

  public async refreshTreeValues() {
    let articleFamilies = await this.articleFamilyService?.getEntities(null, null, { activityCodeId: this.selectedActivites?.id });
    this.articleFamiliesTree.splice(0)
    this.selectedTreeValues.splice(0);
    articleFamilies?.filter(x => x.mainFamilyId == null).forEach(articleFamily => {
      let children: Array<UITreeNode> = null;
      if (articleFamily.subFamilies != null && articleFamily.subFamilies.length > 0) {
        children = this.getChildren(articleFamily);
      }
      let articleFamilyTreeItem: UITreeNode = new UITreeNode({
        id: articleFamily.id.toString(),
        label: articleFamily.familyName._translation,
        expanded: true,
        disabled: true,
        leaf: (children == null),
        children: children,
        icon: "digi-folder-line"
      });
      this.articleFamiliesTree.push(articleFamilyTreeItem);
    });
  }

  private getChildren(parentArticleFamily: Merlin.Web.Model.ItemFamily): Array<UITreeNode> {
    let articleFamilies: Array<UITreeNode> = new Array<UITreeNode>();
    parentArticleFamily.subFamilies.forEach(articleFamily => {
      let children: Array<UITreeNode> = null;
      if (articleFamily.subFamilies != null && articleFamily.subFamilies.length > 0) {
        children = this.getChildren(articleFamily)
      }
      let articleFamilyTreeItem: UITreeNode = new UITreeNode({
        id: articleFamily.id.toString(),
        label: articleFamily.familyName._translation,
        expanded: true,
        disabled: true,
        leaf: (children == null),
        children: children,
        icon: "digi-folder-line"
      });
      articleFamilies.push(articleFamilyTreeItem);
    });
    return articleFamilies;
  }

  public initializeGridOptions() {
    super.initializeGridOptions(false, false);
  }

  public afterFilterChanged() {
    setTimeout(() => {
      this.agGrid.calculateBodyHeight(0);
    }, 1);
  }

  public getDataGridColumns() {
    let itemDeliveryService = new EnumerationTypeService(Constants.EnumerationTypes.ItemDeliveryType);
    let itemFeaturesService = new EnumerationTypeService(Constants.EnumerationTypes.ItemFeature);
    let activityCodeService = new EnumerationTypeService(Constants.EnumerationTypes.ActivityCode);
    let defs: ColDef[] = [
      {
        headerName: this.i18n.tr("item.articleCode"),
        field: "articleCode",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("item.description"),
        field: "description._translation",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.descriptions")
      },
      {
        headerName: this.i18n.tr("item.detailedDescriptionMultiline"),
        field: "detailedDescriptionMultiline._translation",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.descriptions")
      },
      {
        headerName: this.i18n.tr("item.secondaryDescriptionMultiline"),
        field: "secondaryDescriptionMultiline._translation",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.descriptions")
      },
      {
        headerName: this.i18n.tr("item.isDeprecated"),
        field: "isDeprecated",
        type: FieldType.Boolean,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("item.isValidated"),
        field: "isValidated",
        type: FieldType.Boolean,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("item.supplierWarrantyInMonth"),
        field: "supplierWarrantyInMonth",
        type: FieldType.Number,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("item.clientWarrantyInMonth"),
        field: "clientWarrantyInMonth",
        type: FieldType.Number,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("item.itemTypeId"),
        field: "itemType.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.ItemType,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.ItemType,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("item.activityCodes"),
        field: "activityCodes.activityCodeEnum.denomination._translation",
        type: FieldType.ManyToMany,
        filterParams: {
          service: activityCodeService
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: activityCodeService,
        },
        cellRenderer: "manyToManyTagRenderer",
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("item.itemDeliveryTypes"),
        field: "itemDeliveryTypes.itemDeliveryEnum.denomination._translation",
        type: FieldType.ManyToMany,
        filterParams: {
          service: itemDeliveryService
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: itemDeliveryService,
        },
        cellRenderer: "manyToManyTagRenderer",
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("item.unitTypeId"),
        field: "unitType.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.UnitType,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.UnitType,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("item.defaultUnitId"),
        field: "defaultUnit.unitName._translation",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("item.defaultBuyingPrice"),
        field: "defaultBuyingPrice",
        type: FieldType.Currency,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("item.defaultSellingPrice"),
        field: "defaultSellingPrice",
        type: FieldType.Currency,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("item.features"),
        field: "features.itemFeatureEnum.denomination._translation",
        type: FieldType.ManyToMany,
        filterParams: {
          service: itemFeaturesService
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: itemFeaturesService,
        },
        cellRenderer: "manyToManyTagRenderer",
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      ...new GridHelperMerlin().baseEntityColDef(this),
    ];
    return defs;
  }
}

export class PriceOfferLineFromItem {
  itemId: number;
  item: Merlin.Web.Model.Item;
  description: string;
  unitId: number;
  unit: Merlin.Web.Model.Unit;
  quantity: number;
  price: number;
  action: Constants.PriceOfferLineMoveAction;
  targetId: number;
  workQuotationVersionId: number | null;
  priceOfferVersionId: number | null;
  projectId: number | null;
  itemCompositionId: number | null;
  marginCoef: number;
  total: number;
  unitService: ServiceBase<Merlin.Web.Model.Unit>;
  nbUnit: number;
  activity: EnumerationType;
  activityId: number | null;
  nbActivity: number | null;
  activityService: EnumerationTypeService;
  delivranceType: EnumerationType;
  delivranceTypeId: number | null;
  nbDelivery: number | null
  deliveryService: EnumerationTypeService;
  IsComposition: boolean;
}
