import { JSONPatchOperation } from './json-patch-operation';
import { ActionDialogBoxInputParameters, Box, CustomLogger, DialogBoxViewModel, GlobalLoaderService, KeyboardShortcut } from 'digiwall-lib';
import { singleton, autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import { I18N } from 'aurelia-i18n';
import { Merlin } from 'generated';
import * as Constants from '../constants';
import * as ModuleItem from '../items/module-item';
import { BaseApiService } from './base-api-service';
import { BulkUpdateOperation, IDataLineApiService, MeteringTotalPrices, RequestCreatePriceOfferLine, RequestFilterPriceOfferLine, RequestMovePriceOfferLine } from './i-data-line-api-service';
import { PriceOfferLineFromItem } from 'projects/quotes/add-items/add-items';
import { FiltersUtils } from 'resources/utilities/filter-utils';
import { RefToDisplay } from '../constants';
import { LineToItem } from 'projects/quotes/create-item-drom-dataline/create-item-drom-dataline';

@singleton()
@autoinject()
export class ItemApiService extends BaseApiService {
  constructor(private httpClient: HttpClient, public i18n: I18N, public box: Box, private gls: GlobalLoaderService) {
    super(i18n, box);
  }

  //#region Item

  public async getItemCurrentPricesFromLine(entity: Merlin.Web.Model.ItemCompositionLine): Promise<ModuleItem.ItemCurrentPriceResponse> {
    let query = await this.httpClient.post(Constants.Application.ItemController.GetCurrentItemPrice, JSON.stringify(ModuleItem.ItemCurrentPriceRequest.map(entity)));
    if (query.ok && query.status == 200) {
      return await query.json();
    }
    this.manageError(query);
  }

  public async getItemCurrentPrices(itemId: number): Promise<ModuleItem.ItemCurrentPrice> {
    let query = await this.httpClient.post(Constants.Application.ItemController.GetCurrentPrice.format(itemId.toString()));
    if (query.ok && query.status == 200) {
      return await query.json();
    }
    this.manageError(query);
  }

  public async patchItem(id: number, propertyName: string, propertyValue: any): Promise<number> {
    let requestUri = Constants.Application.ItemController.PatchItem.format(id.toString());
    let query = await this.httpClient.patch(requestUri, JSON.stringify(JSONPatchOperation.operateReplace(propertyName, propertyValue)));
    if (query.ok && query.status == 200) {
      return await query.json();
    }
    this.manageError(query);
  }

  public async updateItemLink(id: number) {
    let requestUri = Constants.Application.ItemController.UpdateItemLink.format(id.toString());
    let query = await this.httpClient.post(requestUri);
    if (query.ok) {
      if (query.status == 200) {
        return await query.json();
      }
      return true;
    }
    this.manageError(query);
  }

  //#endregion

  //#region ItemPrice

  /**
   * Return a non-saved ItemPrice linked to the given Item itemId.
   *
   * @param {number} itemId
   * @return {*}  {Promise<number>} Id of the new created ItemPrice.
   * @memberof ItemApiService
   */
  public async newItemPrice(itemId: number): Promise<Merlin.Web.Model.ItemPrice> {
    this.gls.allow();
    let requestUri = Constants.Application.ItemController.NewItemPrice.format(itemId.toString());
    let query = await this.httpClient.get(requestUri);
    if (query.ok && query.status == 200) {
      return await query.json();
    }
    this.manageError(query);
  }

  public async canDeleteItemPrice(itemPriceId: number): Promise<boolean> {
    let query = await this.httpClient.get(Constants.Application.ItemController.CanDeleteItemPrice.format(itemPriceId.toString()));
    if (query.ok) {
      return true;
    }
    this.manageError(query);
    return false;
  }

  public async updateItemPrices(itemPrices: Array<Merlin.Web.Model.ItemPrice>): Promise<boolean> {
    let query = await this.httpClient.put(Constants.Application.ItemController.UpdateItemPrices, JSON.stringify(itemPrices.map(ip => ModuleItem.ItemPriceDTO.fromItemPrice(ip))));
    if (query.ok) {
      return true;
    }
    this.manageError(query);
    return false;
  }

  //#endregion

  //#region ItemPriceFromDates
  public async newItemPriceFromDate(itemPriceId: number): Promise<Merlin.Web.Model.ItemPriceFromDate> {
    this.gls.allow();
    let requestUri = Constants.Application.ItemController.NewItemPriceFromDate.format(itemPriceId.toString());
    let query = await this.httpClient.get(requestUri);
    if (query.ok && query.status == 200) {
      return await query.json();
    }
    this.manageError(query);
  }

  //#endregion

  //#region ItemComposition
  public async newItemComposition(itemId: number): Promise<Merlin.Web.Model.ItemComposition> {
    this.gls.allow();
    let requestUri = Constants.Application.ItemController.NewItemComposition.format(itemId.toString());
    let query = await this.httpClient.get(requestUri);
    if (query.ok && query.status == 200) {
      return await query.json();
    }
    this.manageError(query);
  }

  public async updateItemCompositions(itemCompos: Array<Merlin.Web.Model.ItemComposition>): Promise<boolean> {
    this.gls.allow();
    let requestUri = Constants.Application.ItemController.UpdateItemComposition;
    let query = await this.httpClient.put(requestUri, JSON.stringify(itemCompos.map(ip => ModuleItem.ItemCompositionDTO.fromItemComposition(ip))));
    if (query.ok) {
      return true;
    }
    this.manageError(query);
    return false;
  }

  public async computePrices(itemCompo: Merlin.Web.Model.ItemComposition): Promise<Merlin.Web.Model.ItemComposition> {
    let requestUri = Constants.Application.ItemController.Compute;
    let query = await this.httpClient.post(requestUri, JSON.stringify(ModuleItem.ItemCompositionDTO.fromItemComposition(itemCompo)));
    if (query.ok && query.status == 200) {
      return await query.json();
    }
    this.manageError(query);
  }

  public async CreateUnvalidatedItem(dtos: Array<LineToItem>): Promise<boolean> {
    let requestUri = Constants.Application.ItemController.CreateUnvalidatedItem;
    let query = await this.httpClient.post(requestUri, JSON.stringify(dtos));
    if (query.ok && query.status == 200) {
      return await query.json();
    }
    this.manageError(query);
  }

  //#endregion

  //#region ItemCompositionLine
  public async newItemCompositionLine(itemCompositionId: number): Promise<Merlin.Web.Model.ItemCompositionLine> {
    let requestUri = Constants.Application.ItemController.NewItemCompositionLine.format(itemCompositionId.toString());
    let query = await this.httpClient.get(requestUri);
    if (query.ok && query.status == 200) {
      return await query.json();
    }
    this.manageError(query);
  }

  //#endregion
}

@singleton()
@autoinject
export class ItemCompositionApiService implements IDataLineApiService {
  protected callback: () => void;
  constructor(protected httpClient: HttpClient, private box: Box, private i18n: I18N, protected filtersUtils: FiltersUtils, protected globalLoaderService: GlobalLoaderService, private logger: CustomLogger) { }
  getLastChapterId(versionId: number): Promise<number> {
    throw new Error('Method not implemented.');
  }
  recomputeLines(versionId: number): Promise<MeteringTotalPrices> {
    throw new Error('Method not implemented.');
  }
  bulkPatch(versionId: number, bulkUpdateOperation: BulkUpdateOperation[]): Promise<any[]> {
    throw new Error('Method not implemented.');
  }
  duplicateLine?(versionId: number, lineId: number): Promise<number[]> {
    throw new Error('Method not implemented.');
  }
  paste(versionId: number, lineIds: number[], targetId: number, moveType: Constants.PriceOfferLineMoveAction): Promise<number[]> {
    throw new Error('Method not implemented.');
  }
  computePAR?(versionId: number): Promise<boolean> {
    throw new Error('Method not implemented.');
  }
  deletePAR?(versionId: number, lineId: number): Promise<boolean> {
    throw new Error('Method not implemented.');
  }
  versionHasLines?(versionId: number): Promise<boolean> {
    throw new Error('Method not implemented.');
  }
  getMeteringTotalPrices?(versionId: number): Promise<MeteringTotalPrices> {
    throw new Error('Method not implemented.');
  }
  createVariantGroup?(versionId: number, lineIds: number[]): Promise<number[]> {
    throw new Error('Method not implemented.');
  }
  deleteVariantGroup?(versionId: number, lineId: number): Promise<number[]> {
    throw new Error('Method not implemented.');
  }

  subscribe(callback: () => void) {
    this.callback = callback;
  }

  public async children(versionId: number, lineId: number, displayHidden: boolean, refToDisplay: RefToDisplay): Promise<Array<any>> {
    let requestUri = Constants.Application.ItemCompositionController.GetChildren.format(versionId.toString(), lineId.toString(), displayHidden.toString());
    let query = await this.httpClient.get(requestUri);
    if (query.ok) {
      return await query.json();
    }
  }

  public async filter(versionId: number, filterParams: any, quickFilter: string, displayMode: Constants.TreeDisplayMode, displayHidden: boolean, refToDisplay: RefToDisplay): Promise<Array<any>> {
    let requestParams: RequestFilterPriceOfferLine = {
      filterModel: filterParams,
      quickFilter: quickFilter,
      displayMode: displayMode,
      displayHidden: displayHidden,
      refToDisplay: refToDisplay
    };
    let requestUri = Constants.Application.ItemCompositionController.GetAll.format(versionId.toString());
    let response = await this.httpClient.post(requestUri, JSON.stringify(requestParams));
    if (response.ok) {
      return await response.json();
    }
  }

  public async fetch(versionId: number, ids: number[], displayHidden: boolean, refToDisplay: RefToDisplay) {
    let result = await this.httpClient.post(Constants.Application.ItemCompositionController.GetByIds.format(versionId.toString(), displayHidden.toString()), JSON.stringify(ids));
    if (result.ok) {
      let entities: Array<any> = await result.json();
      return entities;
    }
    else {
      return null;
    }
  }

  public async patch(versionId: number, lineId: number, propertyName: string, propertyValue: any): Promise<Array<any>> {
    let requestUri = Constants.Application.ItemCompositionController.Patch.format(versionId.toString(), lineId.toString());
    let patchResponse = await this.httpClient.patch(requestUri, JSON.stringify(JSONPatchOperation.operateReplace(propertyName, propertyValue)));
    if (patchResponse.ok) {
      return await patchResponse.json();
    } else {
      if (patchResponse.status == 400) {
        return [lineId];
      }
    }
    return [];
  }
  public async move(versionId: number, lineIds: number[], targetId: number, moveType: Constants.PriceOfferLineMoveAction): Promise<number[]> {
    let requestMovePriceOfferLine: RequestMovePriceOfferLine = {
      action: moveType,
      targetId: targetId,
      priceOfferLineIds: lineIds
    }

    let requestUri = Constants.Application.ItemCompositionController.Move.format(versionId.toString());
    let response = await this.httpClient.post(requestUri, JSON.stringify(requestMovePriceOfferLine));
    if (response.ok) {
      return await response.json();
    }

    return [];
  }
  public async delete(versionId: number, lineIds: number[]): Promise<Array<number>> {
    let msg = this.i18n.tr('menu.delete_confirmation');
    let ids = [];

    let buttonYes: ActionDialogBoxInputParameters =
    {
      label: this.i18n.tr("general.ok", { ns: "common" }),
      title: this.i18n.tr("general.ok", { ns: "common" }),
      theme: 'primary',
      type: 'solid',
      disabled: false,
      fn: (thisBox: DialogBoxViewModel) => {
        thisBox.controller.ok(true);
      },
      keyboardShortcut: KeyboardShortcut.Enter
    };
    let buttonNo: ActionDialogBoxInputParameters =
    {
      label: this.i18n.tr("general.cancel", { ns: "common" }),
      title: this.i18n.tr("general.cancel", { ns: "common" }),
      theme: 'dark',
      type: 'ghost',
      disabled: false,
      fn: (thisBox: DialogBoxViewModel) => {
        thisBox.controller.ok(false);
      },
      keyboardShortcut: KeyboardShortcut.Escape
    };
    await this.box.showQuestion(msg, this.i18n.tr("menu.question", { ns: "common" }), [buttonNo, buttonYes])
      .whenClosed(async (result) => {
        if (!result.wasCancelled && result.output) {
          let requestUri = Constants.Application.ItemCompositionController.DeleteLine.format(versionId.toString());
          let response = await this.httpClient.delete(requestUri, JSON.stringify(lineIds));
          if (response.ok) {
            let temp = await response.json();
            ids.push(...temp);
          } else {
            if (response.status == 400) {
              this.logger.LogError(this.i18n.tr('versionmetering.notCanDeleteLink'), null, null, true);
            }
          }
        }
      });
    return ids;
  }

  public async createFromItems(versionId: number, selectedItems: PriceOfferLineFromItem[]): Promise<number[]> {
    let requestUri = Constants.Application.ItemCompositionController.CreateFromItems.format(versionId.toString());
    let query = await this.httpClient.post(requestUri, JSON.stringify(selectedItems));

    if (query.ok) {
      return await query.json();
    }
    else {
      this.logger.LogError(this.i18n.tr(await query.json()), null, null, true);
    }
    return null;
  }

  public async create(versionId: number, targetId: number, categoryId: number, action: Constants.PriceOfferLineMoveAction): Promise<number[]> {
    let requestCreatePriceOfferLine: RequestCreatePriceOfferLine = {
      targetId: targetId,
      categoryId: categoryId,
      fK: versionId,
      action: action
    };

    let requestUri = Constants.Application.ItemCompositionController.Create.format(versionId.toString());
    let query = await this.httpClient.post(requestUri, JSON.stringify(requestCreatePriceOfferLine));
    if (query.ok) {
      return await query.json();
    }
    return [];
  }

  select(lineId: number, filterModel?: { [key: string]: any; }) {
    return
  }
  unselect(lineId: number, filterModel?: { [key: string]: any; }) {
    return
  }

}
