import { autoinject, observable } from "aurelia-framework";
import { DialogController } from "aurelia-dialog";
import { I18N } from "aurelia-i18n";

@autoinject
export class InsertLink {
  @observable
  private url: string;
  private textToDisplay: string;
  private linkTitle: string;
  private title: string = this.i18n.tr("insertlink.insertlink")
  private targets: Array<{ target: string, label: string }>;
  private selectedTarget: string = LinkTarget.Self;

  constructor(private dialogController: DialogController, private i18n: I18N) {

  }
  public activate(params) {
    this.initTargets();
  }

  public async save() {
    await this.dialogController.ok(this.buildHtmlLink());
  }

  public async cancel() {
    await this.dialogController.close(false);
  }

  private urlChanged(newVal, oldVal) {
    if (this.textToDisplay == oldVal) {
      this.textToDisplay = this.url
    }
  }

  private buildHtmlLink() {
    let htmlLink: string = "";
    if (this.url != null && this.url.trim() && this.textToDisplay != null && this.textToDisplay.trim()) {
      htmlLink += "<a ";
      if (this.linkTitle != null && this.linkTitle.trim()) {
        htmlLink += `title="${this.linkTitle}" `
      }
      if (this.selectedTarget != null && this.selectedTarget.trim()) {
        console.log(this.selectedTarget)
        htmlLink += `target="${this.selectedTarget}" `
      }
      htmlLink += `href="${this.formatUrl(this.url)}">${this.textToDisplay}<a>`
    }
    return htmlLink;
  }

  private initTargets() {
    this.targets = [
      {
        target: LinkTarget.Self,
        label: this.i18n.tr("insertlink.self")
      },
      {
        target: LinkTarget.Blank,
        label: this.i18n.tr("insertlink.blank")
      }
    ]
  }

  private formatUrl(url: string): string {
    // Trim any extra spaces
    url = url.trim();

    // Add https:// if it doesn't start with http:// or https://
    if (!/^https?:\/\//i.test(url)) {
      url = 'https://' + url;
    }
    return url
  }
}

export enum LinkTarget {
  Self = "_self",
  Blank = "_blank"
}
